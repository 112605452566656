// https://unicode.org/Public/emoji/16.0/emoji-test.txt
/*
copy((() => {
  const lines = str.split('\n');
  let group;
  let subgroup;

  const allGroups = {};
  lines.forEach((line) => {
      const groupMatch = line.match(/^#\sgroup:\s+(.+)$/u);
      const subgroupMatch = line.match(/^#\ssubgroup:\s+(.+)$/u);
      const emojiMatch = line.match(/\s+;\s+\S+\s+#\s+(\S+)\s+E\S+\s+/u);

      if (groupMatch && group !== groupMatch[1]) {
          group = groupMatch[1];
          if (!allGroups[group]) allGroups[group] = {}
      }

      if (group != null) {
          const subgroups = allGroups[group];
  
          if (subgroupMatch && subgroup !== subgroupMatch[1]) {
              subgroup = subgroupMatch[1];
              if (!subgroups[subgroup]) subgroups[subgroup] = [];
          }

          if (subgroup != null) {
              const emojis = subgroups[subgroup];
      
              if (emojiMatch) {
                  emojis.push(emojiMatch[1]);
              }
          }

      }
  });

  return allGroups;
})());
*/

const allEmojis = {
  "Smileys & Emotion": {
      "face-smiling": [
          "😀",
          "😃",
          "😄",
          "😁",
          "😆",
          "😅",
          "🤣",
          "😂",
          "🙂",
          "🙃",
          "🫠",
          "😉",
          "😊",
          "😇"
      ],
      "face-affection": [
          "🥰",
          "😍",
          "🤩",
          "😘",
          "😗",
          "☺️",
          "☺",
          "😚",
          "😙",
          "🥲"
      ],
      "face-tongue": [
          "😋",
          "😛",
          "😜",
          "🤪",
          "😝",
          "🤑"
      ],
      "face-hand": [
          "🤗",
          "🤭",
          "🫢",
          "🫣",
          "🤫",
          "🤔",
          "🫡"
      ],
      "face-neutral-skeptical": [
          "🤐",
          "🤨",
          "😐",
          "😑",
          "😶",
          "🫥",
          "😶‍🌫️",
          "😶‍🌫",
          "😏",
          "😒",
          "🙄",
          "😬",
          "😮‍💨",
          "🤥",
          "🫨",
          "🙂‍↔️",
          "🙂‍↔",
          "🙂‍↕️",
          "🙂‍↕"
      ],
      "face-sleepy": [
          "😌",
          "😔",
          "😪",
          "🤤",
          "😴",
          "🫩"
      ],
      "face-unwell": [
          "😷",
          "🤒",
          "🤕",
          "🤢",
          "🤮",
          "🤧",
          "🥵",
          "🥶",
          "🥴",
          "😵",
          "😵‍💫",
          "🤯"
      ],
      "face-hat": [
          "🤠",
          "🥳",
          "🥸"
      ],
      "face-glasses": [
          "😎",
          "🤓",
          "🧐"
      ],
      "face-concerned": [
          "😕",
          "🫤",
          "😟",
          "🙁",
          "☹️",
          "☹",
          "😮",
          "😯",
          "😲",
          "😳",
          "🥺",
          "🥹",
          "😦",
          "😧",
          "😨",
          "😰",
          "😥",
          "😢",
          "😭",
          "😱",
          "😖",
          "😣",
          "😞",
          "😓",
          "😩",
          "😫",
          "🥱"
      ],
      "face-negative": [
          "😤",
          "😡",
          "😠",
          "🤬",
          "😈",
          "👿",
          "💀",
          "☠️",
          "☠"
      ],
      "face-costume": [
          "💩",
          "🤡",
          "👹",
          "👺",
          "👻",
          "👽",
          "👾",
          "🤖"
      ],
      "cat-face": [
          "😺",
          "😸",
          "😹",
          "😻",
          "😼",
          "😽",
          "🙀",
          "😿",
          "😾"
      ],
      "monkey-face": [
          "🙈",
          "🙉",
          "🙊"
      ],
      "heart": [
          "💌",
          "💘",
          "💝",
          "💖",
          "💗",
          "💓",
          "💞",
          "💕",
          "💟",
          "❣️",
          "❣",
          "💔",
          "❤️‍🔥",
          "❤‍🔥",
          "❤️‍🩹",
          "❤‍🩹",
          "❤️",
          "❤",
          "🩷",
          "🧡",
          "💛",
          "💚",
          "💙",
          "🩵",
          "💜",
          "🤎",
          "🖤",
          "🩶",
          "🤍"
      ],
      "emotion": [
          "💋",
          "💯",
          "💢",
          "💥",
          "💫",
          "💦",
          "💨",
          "🕳️",
          "🕳",
          "💬",
          "👁️‍🗨️",
          "👁‍🗨️",
          "👁️‍🗨",
          "👁‍🗨",
          "🗨️",
          "🗨",
          "🗯️",
          "🗯",
          "💭",
          "💤"
      ]
  },
  "People & Body": {
      "hand-fingers-open": [
          "👋",
          "👋🏻",
          "👋🏼",
          "👋🏽",
          "👋🏾",
          "👋🏿",
          "🤚",
          "🤚🏻",
          "🤚🏼",
          "🤚🏽",
          "🤚🏾",
          "🤚🏿",
          "🖐️",
          "🖐",
          "🖐🏻",
          "🖐🏼",
          "🖐🏽",
          "🖐🏾",
          "🖐🏿",
          "✋",
          "✋🏻",
          "✋🏼",
          "✋🏽",
          "✋🏾",
          "✋🏿",
          "🖖",
          "🖖🏻",
          "🖖🏼",
          "🖖🏽",
          "🖖🏾",
          "🖖🏿",
          "🫱",
          "🫱🏻",
          "🫱🏼",
          "🫱🏽",
          "🫱🏾",
          "🫱🏿",
          "🫲",
          "🫲🏻",
          "🫲🏼",
          "🫲🏽",
          "🫲🏾",
          "🫲🏿",
          "🫳",
          "🫳🏻",
          "🫳🏼",
          "🫳🏽",
          "🫳🏾",
          "🫳🏿",
          "🫴",
          "🫴🏻",
          "🫴🏼",
          "🫴🏽",
          "🫴🏾",
          "🫴🏿",
          "🫷",
          "🫷🏻",
          "🫷🏼",
          "🫷🏽",
          "🫷🏾",
          "🫷🏿",
          "🫸",
          "🫸🏻",
          "🫸🏼",
          "🫸🏽",
          "🫸🏾",
          "🫸🏿"
      ],
      "hand-fingers-partial": [
          "👌",
          "👌🏻",
          "👌🏼",
          "👌🏽",
          "👌🏾",
          "👌🏿",
          "🤌",
          "🤌🏻",
          "🤌🏼",
          "🤌🏽",
          "🤌🏾",
          "🤌🏿",
          "🤏",
          "🤏🏻",
          "🤏🏼",
          "🤏🏽",
          "🤏🏾",
          "🤏🏿",
          "✌️",
          "✌",
          "✌🏻",
          "✌🏼",
          "✌🏽",
          "✌🏾",
          "✌🏿",
          "🤞",
          "🤞🏻",
          "🤞🏼",
          "🤞🏽",
          "🤞🏾",
          "🤞🏿",
          "🫰",
          "🫰🏻",
          "🫰🏼",
          "🫰🏽",
          "🫰🏾",
          "🫰🏿",
          "🤟",
          "🤟🏻",
          "🤟🏼",
          "🤟🏽",
          "🤟🏾",
          "🤟🏿",
          "🤘",
          "🤘🏻",
          "🤘🏼",
          "🤘🏽",
          "🤘🏾",
          "🤘🏿",
          "🤙",
          "🤙🏻",
          "🤙🏼",
          "🤙🏽",
          "🤙🏾",
          "🤙🏿"
      ],
      "hand-single-finger": [
          "👈",
          "👈🏻",
          "👈🏼",
          "👈🏽",
          "👈🏾",
          "👈🏿",
          "👉",
          "👉🏻",
          "👉🏼",
          "👉🏽",
          "👉🏾",
          "👉🏿",
          "👆",
          "👆🏻",
          "👆🏼",
          "👆🏽",
          "👆🏾",
          "👆🏿",
          "🖕",
          "🖕🏻",
          "🖕🏼",
          "🖕🏽",
          "🖕🏾",
          "🖕🏿",
          "👇",
          "👇🏻",
          "👇🏼",
          "👇🏽",
          "👇🏾",
          "👇🏿",
          "☝️",
          "☝",
          "☝🏻",
          "☝🏼",
          "☝🏽",
          "☝🏾",
          "☝🏿",
          "🫵",
          "🫵🏻",
          "🫵🏼",
          "🫵🏽",
          "🫵🏾",
          "🫵🏿"
      ],
      "hand-fingers-closed": [
          "👍",
          "👍🏻",
          "👍🏼",
          "👍🏽",
          "👍🏾",
          "👍🏿",
          "👎",
          "👎🏻",
          "👎🏼",
          "👎🏽",
          "👎🏾",
          "👎🏿",
          "✊",
          "✊🏻",
          "✊🏼",
          "✊🏽",
          "✊🏾",
          "✊🏿",
          "👊",
          "👊🏻",
          "👊🏼",
          "👊🏽",
          "👊🏾",
          "👊🏿",
          "🤛",
          "🤛🏻",
          "🤛🏼",
          "🤛🏽",
          "🤛🏾",
          "🤛🏿",
          "🤜",
          "🤜🏻",
          "🤜🏼",
          "🤜🏽",
          "🤜🏾",
          "🤜🏿"
      ],
      "hands": [
          "👏",
          "👏🏻",
          "👏🏼",
          "👏🏽",
          "👏🏾",
          "👏🏿",
          "🙌",
          "🙌🏻",
          "🙌🏼",
          "🙌🏽",
          "🙌🏾",
          "🙌🏿",
          "🫶",
          "🫶🏻",
          "🫶🏼",
          "🫶🏽",
          "🫶🏾",
          "🫶🏿",
          "👐",
          "👐🏻",
          "👐🏼",
          "👐🏽",
          "👐🏾",
          "👐🏿",
          "🤲",
          "🤲🏻",
          "🤲🏼",
          "🤲🏽",
          "🤲🏾",
          "🤲🏿",
          "🤝",
          "🤝🏻",
          "🤝🏼",
          "🤝🏽",
          "🤝🏾",
          "🤝🏿",
          "🫱🏻‍🫲🏼",
          "🫱🏻‍🫲🏽",
          "🫱🏻‍🫲🏾",
          "🫱🏻‍🫲🏿",
          "🫱🏼‍🫲🏻",
          "🫱🏼‍🫲🏽",
          "🫱🏼‍🫲🏾",
          "🫱🏼‍🫲🏿",
          "🫱🏽‍🫲🏻",
          "🫱🏽‍🫲🏼",
          "🫱🏽‍🫲🏾",
          "🫱🏽‍🫲🏿",
          "🫱🏾‍🫲🏻",
          "🫱🏾‍🫲🏼",
          "🫱🏾‍🫲🏽",
          "🫱🏾‍🫲🏿",
          "🫱🏿‍🫲🏻",
          "🫱🏿‍🫲🏼",
          "🫱🏿‍🫲🏽",
          "🫱🏿‍🫲🏾",
          "🙏",
          "🙏🏻",
          "🙏🏼",
          "🙏🏽",
          "🙏🏾",
          "🙏🏿"
      ],
      "hand-prop": [
          "✍️",
          "✍",
          "✍🏻",
          "✍🏼",
          "✍🏽",
          "✍🏾",
          "✍🏿",
          "💅",
          "💅🏻",
          "💅🏼",
          "💅🏽",
          "💅🏾",
          "💅🏿",
          "🤳",
          "🤳🏻",
          "🤳🏼",
          "🤳🏽",
          "🤳🏾",
          "🤳🏿"
      ],
      "body-parts": [
          "💪",
          "💪🏻",
          "💪🏼",
          "💪🏽",
          "💪🏾",
          "💪🏿",
          "🦾",
          "🦿",
          "🦵",
          "🦵🏻",
          "🦵🏼",
          "🦵🏽",
          "🦵🏾",
          "🦵🏿",
          "🦶",
          "🦶🏻",
          "🦶🏼",
          "🦶🏽",
          "🦶🏾",
          "🦶🏿",
          "👂",
          "👂🏻",
          "👂🏼",
          "👂🏽",
          "👂🏾",
          "👂🏿",
          "🦻",
          "🦻🏻",
          "🦻🏼",
          "🦻🏽",
          "🦻🏾",
          "🦻🏿",
          "👃",
          "👃🏻",
          "👃🏼",
          "👃🏽",
          "👃🏾",
          "👃🏿",
          "🧠",
          "🫀",
          "🫁",
          "🦷",
          "🦴",
          "👀",
          "👁️",
          "👁",
          "👅",
          "👄",
          "🫦"
      ],
      "person": [
          "👶",
          "👶🏻",
          "👶🏼",
          "👶🏽",
          "👶🏾",
          "👶🏿",
          "🧒",
          "🧒🏻",
          "🧒🏼",
          "🧒🏽",
          "🧒🏾",
          "🧒🏿",
          "👦",
          "👦🏻",
          "👦🏼",
          "👦🏽",
          "👦🏾",
          "👦🏿",
          "👧",
          "👧🏻",
          "👧🏼",
          "👧🏽",
          "👧🏾",
          "👧🏿",
          "🧑",
          "🧑🏻",
          "🧑🏼",
          "🧑🏽",
          "🧑🏾",
          "🧑🏿",
          "👱",
          "👱🏻",
          "👱🏼",
          "👱🏽",
          "👱🏾",
          "👱🏿",
          "👨",
          "👨🏻",
          "👨🏼",
          "👨🏽",
          "👨🏾",
          "👨🏿",
          "🧔",
          "🧔🏻",
          "🧔🏼",
          "🧔🏽",
          "🧔🏾",
          "🧔🏿",
          "🧔‍♂️",
          "🧔‍♂",
          "🧔🏻‍♂️",
          "🧔🏻‍♂",
          "🧔🏼‍♂️",
          "🧔🏼‍♂",
          "🧔🏽‍♂️",
          "🧔🏽‍♂",
          "🧔🏾‍♂️",
          "🧔🏾‍♂",
          "🧔🏿‍♂️",
          "🧔🏿‍♂",
          "🧔‍♀️",
          "🧔‍♀",
          "🧔🏻‍♀️",
          "🧔🏻‍♀",
          "🧔🏼‍♀️",
          "🧔🏼‍♀",
          "🧔🏽‍♀️",
          "🧔🏽‍♀",
          "🧔🏾‍♀️",
          "🧔🏾‍♀",
          "🧔🏿‍♀️",
          "🧔🏿‍♀",
          "👨‍🦰",
          "👨🏻‍🦰",
          "👨🏼‍🦰",
          "👨🏽‍🦰",
          "👨🏾‍🦰",
          "👨🏿‍🦰",
          "👨‍🦱",
          "👨🏻‍🦱",
          "👨🏼‍🦱",
          "👨🏽‍🦱",
          "👨🏾‍🦱",
          "👨🏿‍🦱",
          "👨‍🦳",
          "👨🏻‍🦳",
          "👨🏼‍🦳",
          "👨🏽‍🦳",
          "👨🏾‍🦳",
          "👨🏿‍🦳",
          "👨‍🦲",
          "👨🏻‍🦲",
          "👨🏼‍🦲",
          "👨🏽‍🦲",
          "👨🏾‍🦲",
          "👨🏿‍🦲",
          "👩",
          "👩🏻",
          "👩🏼",
          "👩🏽",
          "👩🏾",
          "👩🏿",
          "👩‍🦰",
          "👩🏻‍🦰",
          "👩🏼‍🦰",
          "👩🏽‍🦰",
          "👩🏾‍🦰",
          "👩🏿‍🦰",
          "🧑‍🦰",
          "🧑🏻‍🦰",
          "🧑🏼‍🦰",
          "🧑🏽‍🦰",
          "🧑🏾‍🦰",
          "🧑🏿‍🦰",
          "👩‍🦱",
          "👩🏻‍🦱",
          "👩🏼‍🦱",
          "👩🏽‍🦱",
          "👩🏾‍🦱",
          "👩🏿‍🦱",
          "🧑‍🦱",
          "🧑🏻‍🦱",
          "🧑🏼‍🦱",
          "🧑🏽‍🦱",
          "🧑🏾‍🦱",
          "🧑🏿‍🦱",
          "👩‍🦳",
          "👩🏻‍🦳",
          "👩🏼‍🦳",
          "👩🏽‍🦳",
          "👩🏾‍🦳",
          "👩🏿‍🦳",
          "🧑‍🦳",
          "🧑🏻‍🦳",
          "🧑🏼‍🦳",
          "🧑🏽‍🦳",
          "🧑🏾‍🦳",
          "🧑🏿‍🦳",
          "👩‍🦲",
          "👩🏻‍🦲",
          "👩🏼‍🦲",
          "👩🏽‍🦲",
          "👩🏾‍🦲",
          "👩🏿‍🦲",
          "🧑‍🦲",
          "🧑🏻‍🦲",
          "🧑🏼‍🦲",
          "🧑🏽‍🦲",
          "🧑🏾‍🦲",
          "🧑🏿‍🦲",
          "👱‍♀️",
          "👱‍♀",
          "👱🏻‍♀️",
          "👱🏻‍♀",
          "👱🏼‍♀️",
          "👱🏼‍♀",
          "👱🏽‍♀️",
          "👱🏽‍♀",
          "👱🏾‍♀️",
          "👱🏾‍♀",
          "👱🏿‍♀️",
          "👱🏿‍♀",
          "👱‍♂️",
          "👱‍♂",
          "👱🏻‍♂️",
          "👱🏻‍♂",
          "👱🏼‍♂️",
          "👱🏼‍♂",
          "👱🏽‍♂️",
          "👱🏽‍♂",
          "👱🏾‍♂️",
          "👱🏾‍♂",
          "👱🏿‍♂️",
          "👱🏿‍♂",
          "🧓",
          "🧓🏻",
          "🧓🏼",
          "🧓🏽",
          "🧓🏾",
          "🧓🏿",
          "👴",
          "👴🏻",
          "👴🏼",
          "👴🏽",
          "👴🏾",
          "👴🏿",
          "👵",
          "👵🏻",
          "👵🏼",
          "👵🏽",
          "👵🏾",
          "👵🏿"
      ],
      "person-gesture": [
          "🙍",
          "🙍🏻",
          "🙍🏼",
          "🙍🏽",
          "🙍🏾",
          "🙍🏿",
          "🙍‍♂️",
          "🙍‍♂",
          "🙍🏻‍♂️",
          "🙍🏻‍♂",
          "🙍🏼‍♂️",
          "🙍🏼‍♂",
          "🙍🏽‍♂️",
          "🙍🏽‍♂",
          "🙍🏾‍♂️",
          "🙍🏾‍♂",
          "🙍🏿‍♂️",
          "🙍🏿‍♂",
          "🙍‍♀️",
          "🙍‍♀",
          "🙍🏻‍♀️",
          "🙍🏻‍♀",
          "🙍🏼‍♀️",
          "🙍🏼‍♀",
          "🙍🏽‍♀️",
          "🙍🏽‍♀",
          "🙍🏾‍♀️",
          "🙍🏾‍♀",
          "🙍🏿‍♀️",
          "🙍🏿‍♀",
          "🙎",
          "🙎🏻",
          "🙎🏼",
          "🙎🏽",
          "🙎🏾",
          "🙎🏿",
          "🙎‍♂️",
          "🙎‍♂",
          "🙎🏻‍♂️",
          "🙎🏻‍♂",
          "🙎🏼‍♂️",
          "🙎🏼‍♂",
          "🙎🏽‍♂️",
          "🙎🏽‍♂",
          "🙎🏾‍♂️",
          "🙎🏾‍♂",
          "🙎🏿‍♂️",
          "🙎🏿‍♂",
          "🙎‍♀️",
          "🙎‍♀",
          "🙎🏻‍♀️",
          "🙎🏻‍♀",
          "🙎🏼‍♀️",
          "🙎🏼‍♀",
          "🙎🏽‍♀️",
          "🙎🏽‍♀",
          "🙎🏾‍♀️",
          "🙎🏾‍♀",
          "🙎🏿‍♀️",
          "🙎🏿‍♀",
          "🙅",
          "🙅🏻",
          "🙅🏼",
          "🙅🏽",
          "🙅🏾",
          "🙅🏿",
          "🙅‍♂️",
          "🙅‍♂",
          "🙅🏻‍♂️",
          "🙅🏻‍♂",
          "🙅🏼‍♂️",
          "🙅🏼‍♂",
          "🙅🏽‍♂️",
          "🙅🏽‍♂",
          "🙅🏾‍♂️",
          "🙅🏾‍♂",
          "🙅🏿‍♂️",
          "🙅🏿‍♂",
          "🙅‍♀️",
          "🙅‍♀",
          "🙅🏻‍♀️",
          "🙅🏻‍♀",
          "🙅🏼‍♀️",
          "🙅🏼‍♀",
          "🙅🏽‍♀️",
          "🙅🏽‍♀",
          "🙅🏾‍♀️",
          "🙅🏾‍♀",
          "🙅🏿‍♀️",
          "🙅🏿‍♀",
          "🙆",
          "🙆🏻",
          "🙆🏼",
          "🙆🏽",
          "🙆🏾",
          "🙆🏿",
          "🙆‍♂️",
          "🙆‍♂",
          "🙆🏻‍♂️",
          "🙆🏻‍♂",
          "🙆🏼‍♂️",
          "🙆🏼‍♂",
          "🙆🏽‍♂️",
          "🙆🏽‍♂",
          "🙆🏾‍♂️",
          "🙆🏾‍♂",
          "🙆🏿‍♂️",
          "🙆🏿‍♂",
          "🙆‍♀️",
          "🙆‍♀",
          "🙆🏻‍♀️",
          "🙆🏻‍♀",
          "🙆🏼‍♀️",
          "🙆🏼‍♀",
          "🙆🏽‍♀️",
          "🙆🏽‍♀",
          "🙆🏾‍♀️",
          "🙆🏾‍♀",
          "🙆🏿‍♀️",
          "🙆🏿‍♀",
          "💁",
          "💁🏻",
          "💁🏼",
          "💁🏽",
          "💁🏾",
          "💁🏿",
          "💁‍♂️",
          "💁‍♂",
          "💁🏻‍♂️",
          "💁🏻‍♂",
          "💁🏼‍♂️",
          "💁🏼‍♂",
          "💁🏽‍♂️",
          "💁🏽‍♂",
          "💁🏾‍♂️",
          "💁🏾‍♂",
          "💁🏿‍♂️",
          "💁🏿‍♂",
          "💁‍♀️",
          "💁‍♀",
          "💁🏻‍♀️",
          "💁🏻‍♀",
          "💁🏼‍♀️",
          "💁🏼‍♀",
          "💁🏽‍♀️",
          "💁🏽‍♀",
          "💁🏾‍♀️",
          "💁🏾‍♀",
          "💁🏿‍♀️",
          "💁🏿‍♀",
          "🙋",
          "🙋🏻",
          "🙋🏼",
          "🙋🏽",
          "🙋🏾",
          "🙋🏿",
          "🙋‍♂️",
          "🙋‍♂",
          "🙋🏻‍♂️",
          "🙋🏻‍♂",
          "🙋🏼‍♂️",
          "🙋🏼‍♂",
          "🙋🏽‍♂️",
          "🙋🏽‍♂",
          "🙋🏾‍♂️",
          "🙋🏾‍♂",
          "🙋🏿‍♂️",
          "🙋🏿‍♂",
          "🙋‍♀️",
          "🙋‍♀",
          "🙋🏻‍♀️",
          "🙋🏻‍♀",
          "🙋🏼‍♀️",
          "🙋🏼‍♀",
          "🙋🏽‍♀️",
          "🙋🏽‍♀",
          "🙋🏾‍♀️",
          "🙋🏾‍♀",
          "🙋🏿‍♀️",
          "🙋🏿‍♀",
          "🧏",
          "🧏🏻",
          "🧏🏼",
          "🧏🏽",
          "🧏🏾",
          "🧏🏿",
          "🧏‍♂️",
          "🧏‍♂",
          "🧏🏻‍♂️",
          "🧏🏻‍♂",
          "🧏🏼‍♂️",
          "🧏🏼‍♂",
          "🧏🏽‍♂️",
          "🧏🏽‍♂",
          "🧏🏾‍♂️",
          "🧏🏾‍♂",
          "🧏🏿‍♂️",
          "🧏🏿‍♂",
          "🧏‍♀️",
          "🧏‍♀",
          "🧏🏻‍♀️",
          "🧏🏻‍♀",
          "🧏🏼‍♀️",
          "🧏🏼‍♀",
          "🧏🏽‍♀️",
          "🧏🏽‍♀",
          "🧏🏾‍♀️",
          "🧏🏾‍♀",
          "🧏🏿‍♀️",
          "🧏🏿‍♀",
          "🙇",
          "🙇🏻",
          "🙇🏼",
          "🙇🏽",
          "🙇🏾",
          "🙇🏿",
          "🙇‍♂️",
          "🙇‍♂",
          "🙇🏻‍♂️",
          "🙇🏻‍♂",
          "🙇🏼‍♂️",
          "🙇🏼‍♂",
          "🙇🏽‍♂️",
          "🙇🏽‍♂",
          "🙇🏾‍♂️",
          "🙇🏾‍♂",
          "🙇🏿‍♂️",
          "🙇🏿‍♂",
          "🙇‍♀️",
          "🙇‍♀",
          "🙇🏻‍♀️",
          "🙇🏻‍♀",
          "🙇🏼‍♀️",
          "🙇🏼‍♀",
          "🙇🏽‍♀️",
          "🙇🏽‍♀",
          "🙇🏾‍♀️",
          "🙇🏾‍♀",
          "🙇🏿‍♀️",
          "🙇🏿‍♀",
          "🤦",
          "🤦🏻",
          "🤦🏼",
          "🤦🏽",
          "🤦🏾",
          "🤦🏿",
          "🤦‍♂️",
          "🤦‍♂",
          "🤦🏻‍♂️",
          "🤦🏻‍♂",
          "🤦🏼‍♂️",
          "🤦🏼‍♂",
          "🤦🏽‍♂️",
          "🤦🏽‍♂",
          "🤦🏾‍♂️",
          "🤦🏾‍♂",
          "🤦🏿‍♂️",
          "🤦🏿‍♂",
          "🤦‍♀️",
          "🤦‍♀",
          "🤦🏻‍♀️",
          "🤦🏻‍♀",
          "🤦🏼‍♀️",
          "🤦🏼‍♀",
          "🤦🏽‍♀️",
          "🤦🏽‍♀",
          "🤦🏾‍♀️",
          "🤦🏾‍♀",
          "🤦🏿‍♀️",
          "🤦🏿‍♀",
          "🤷",
          "🤷🏻",
          "🤷🏼",
          "🤷🏽",
          "🤷🏾",
          "🤷🏿",
          "🤷‍♂️",
          "🤷‍♂",
          "🤷🏻‍♂️",
          "🤷🏻‍♂",
          "🤷🏼‍♂️",
          "🤷🏼‍♂",
          "🤷🏽‍♂️",
          "🤷🏽‍♂",
          "🤷🏾‍♂️",
          "🤷🏾‍♂",
          "🤷🏿‍♂️",
          "🤷🏿‍♂",
          "🤷‍♀️",
          "🤷‍♀",
          "🤷🏻‍♀️",
          "🤷🏻‍♀",
          "🤷🏼‍♀️",
          "🤷🏼‍♀",
          "🤷🏽‍♀️",
          "🤷🏽‍♀",
          "🤷🏾‍♀️",
          "🤷🏾‍♀",
          "🤷🏿‍♀️",
          "🤷🏿‍♀"
      ],
      "person-role": [
          "🧑‍⚕️",
          "🧑‍⚕",
          "🧑🏻‍⚕️",
          "🧑🏻‍⚕",
          "🧑🏼‍⚕️",
          "🧑🏼‍⚕",
          "🧑🏽‍⚕️",
          "🧑🏽‍⚕",
          "🧑🏾‍⚕️",
          "🧑🏾‍⚕",
          "🧑🏿‍⚕️",
          "🧑🏿‍⚕",
          "👨‍⚕️",
          "👨‍⚕",
          "👨🏻‍⚕️",
          "👨🏻‍⚕",
          "👨🏼‍⚕️",
          "👨🏼‍⚕",
          "👨🏽‍⚕️",
          "👨🏽‍⚕",
          "👨🏾‍⚕️",
          "👨🏾‍⚕",
          "👨🏿‍⚕️",
          "👨🏿‍⚕",
          "👩‍⚕️",
          "👩‍⚕",
          "👩🏻‍⚕️",
          "👩🏻‍⚕",
          "👩🏼‍⚕️",
          "👩🏼‍⚕",
          "👩🏽‍⚕️",
          "👩🏽‍⚕",
          "👩🏾‍⚕️",
          "👩🏾‍⚕",
          "👩🏿‍⚕️",
          "👩🏿‍⚕",
          "🧑‍🎓",
          "🧑🏻‍🎓",
          "🧑🏼‍🎓",
          "🧑🏽‍🎓",
          "🧑🏾‍🎓",
          "🧑🏿‍🎓",
          "👨‍🎓",
          "👨🏻‍🎓",
          "👨🏼‍🎓",
          "👨🏽‍🎓",
          "👨🏾‍🎓",
          "👨🏿‍🎓",
          "👩‍🎓",
          "👩🏻‍🎓",
          "👩🏼‍🎓",
          "👩🏽‍🎓",
          "👩🏾‍🎓",
          "👩🏿‍🎓",
          "🧑‍🏫",
          "🧑🏻‍🏫",
          "🧑🏼‍🏫",
          "🧑🏽‍🏫",
          "🧑🏾‍🏫",
          "🧑🏿‍🏫",
          "👨‍🏫",
          "👨🏻‍🏫",
          "👨🏼‍🏫",
          "👨🏽‍🏫",
          "👨🏾‍🏫",
          "👨🏿‍🏫",
          "👩‍🏫",
          "👩🏻‍🏫",
          "👩🏼‍🏫",
          "👩🏽‍🏫",
          "👩🏾‍🏫",
          "👩🏿‍🏫",
          "🧑‍⚖️",
          "🧑‍⚖",
          "🧑🏻‍⚖️",
          "🧑🏻‍⚖",
          "🧑🏼‍⚖️",
          "🧑🏼‍⚖",
          "🧑🏽‍⚖️",
          "🧑🏽‍⚖",
          "🧑🏾‍⚖️",
          "🧑🏾‍⚖",
          "🧑🏿‍⚖️",
          "🧑🏿‍⚖",
          "👨‍⚖️",
          "👨‍⚖",
          "👨🏻‍⚖️",
          "👨🏻‍⚖",
          "👨🏼‍⚖️",
          "👨🏼‍⚖",
          "👨🏽‍⚖️",
          "👨🏽‍⚖",
          "👨🏾‍⚖️",
          "👨🏾‍⚖",
          "👨🏿‍⚖️",
          "👨🏿‍⚖",
          "👩‍⚖️",
          "👩‍⚖",
          "👩🏻‍⚖️",
          "👩🏻‍⚖",
          "👩🏼‍⚖️",
          "👩🏼‍⚖",
          "👩🏽‍⚖️",
          "👩🏽‍⚖",
          "👩🏾‍⚖️",
          "👩🏾‍⚖",
          "👩🏿‍⚖️",
          "👩🏿‍⚖",
          "🧑‍🌾",
          "🧑🏻‍🌾",
          "🧑🏼‍🌾",
          "🧑🏽‍🌾",
          "🧑🏾‍🌾",
          "🧑🏿‍🌾",
          "👨‍🌾",
          "👨🏻‍🌾",
          "👨🏼‍🌾",
          "👨🏽‍🌾",
          "👨🏾‍🌾",
          "👨🏿‍🌾",
          "👩‍🌾",
          "👩🏻‍🌾",
          "👩🏼‍🌾",
          "👩🏽‍🌾",
          "👩🏾‍🌾",
          "👩🏿‍🌾",
          "🧑‍🍳",
          "🧑🏻‍🍳",
          "🧑🏼‍🍳",
          "🧑🏽‍🍳",
          "🧑🏾‍🍳",
          "🧑🏿‍🍳",
          "👨‍🍳",
          "👨🏻‍🍳",
          "👨🏼‍🍳",
          "👨🏽‍🍳",
          "👨🏾‍🍳",
          "👨🏿‍🍳",
          "👩‍🍳",
          "👩🏻‍🍳",
          "👩🏼‍🍳",
          "👩🏽‍🍳",
          "👩🏾‍🍳",
          "👩🏿‍🍳",
          "🧑‍🔧",
          "🧑🏻‍🔧",
          "🧑🏼‍🔧",
          "🧑🏽‍🔧",
          "🧑🏾‍🔧",
          "🧑🏿‍🔧",
          "👨‍🔧",
          "👨🏻‍🔧",
          "👨🏼‍🔧",
          "👨🏽‍🔧",
          "👨🏾‍🔧",
          "👨🏿‍🔧",
          "👩‍🔧",
          "👩🏻‍🔧",
          "👩🏼‍🔧",
          "👩🏽‍🔧",
          "👩🏾‍🔧",
          "👩🏿‍🔧",
          "🧑‍🏭",
          "🧑🏻‍🏭",
          "🧑🏼‍🏭",
          "🧑🏽‍🏭",
          "🧑🏾‍🏭",
          "🧑🏿‍🏭",
          "👨‍🏭",
          "👨🏻‍🏭",
          "👨🏼‍🏭",
          "👨🏽‍🏭",
          "👨🏾‍🏭",
          "👨🏿‍🏭",
          "👩‍🏭",
          "👩🏻‍🏭",
          "👩🏼‍🏭",
          "👩🏽‍🏭",
          "👩🏾‍🏭",
          "👩🏿‍🏭",
          "🧑‍💼",
          "🧑🏻‍💼",
          "🧑🏼‍💼",
          "🧑🏽‍💼",
          "🧑🏾‍💼",
          "🧑🏿‍💼",
          "👨‍💼",
          "👨🏻‍💼",
          "👨🏼‍💼",
          "👨🏽‍💼",
          "👨🏾‍💼",
          "👨🏿‍💼",
          "👩‍💼",
          "👩🏻‍💼",
          "👩🏼‍💼",
          "👩🏽‍💼",
          "👩🏾‍💼",
          "👩🏿‍💼",
          "🧑‍🔬",
          "🧑🏻‍🔬",
          "🧑🏼‍🔬",
          "🧑🏽‍🔬",
          "🧑🏾‍🔬",
          "🧑🏿‍🔬",
          "👨‍🔬",
          "👨🏻‍🔬",
          "👨🏼‍🔬",
          "👨🏽‍🔬",
          "👨🏾‍🔬",
          "👨🏿‍🔬",
          "👩‍🔬",
          "👩🏻‍🔬",
          "👩🏼‍🔬",
          "👩🏽‍🔬",
          "👩🏾‍🔬",
          "👩🏿‍🔬",
          "🧑‍💻",
          "🧑🏻‍💻",
          "🧑🏼‍💻",
          "🧑🏽‍💻",
          "🧑🏾‍💻",
          "🧑🏿‍💻",
          "👨‍💻",
          "👨🏻‍💻",
          "👨🏼‍💻",
          "👨🏽‍💻",
          "👨🏾‍💻",
          "👨🏿‍💻",
          "👩‍💻",
          "👩🏻‍💻",
          "👩🏼‍💻",
          "👩🏽‍💻",
          "👩🏾‍💻",
          "👩🏿‍💻",
          "🧑‍🎤",
          "🧑🏻‍🎤",
          "🧑🏼‍🎤",
          "🧑🏽‍🎤",
          "🧑🏾‍🎤",
          "🧑🏿‍🎤",
          "👨‍🎤",
          "👨🏻‍🎤",
          "👨🏼‍🎤",
          "👨🏽‍🎤",
          "👨🏾‍🎤",
          "👨🏿‍🎤",
          "👩‍🎤",
          "👩🏻‍🎤",
          "👩🏼‍🎤",
          "👩🏽‍🎤",
          "👩🏾‍🎤",
          "👩🏿‍🎤",
          "🧑‍🎨",
          "🧑🏻‍🎨",
          "🧑🏼‍🎨",
          "🧑🏽‍🎨",
          "🧑🏾‍🎨",
          "🧑🏿‍🎨",
          "👨‍🎨",
          "👨🏻‍🎨",
          "👨🏼‍🎨",
          "👨🏽‍🎨",
          "👨🏾‍🎨",
          "👨🏿‍🎨",
          "👩‍🎨",
          "👩🏻‍🎨",
          "👩🏼‍🎨",
          "👩🏽‍🎨",
          "👩🏾‍🎨",
          "👩🏿‍🎨",
          "🧑‍✈️",
          "🧑‍✈",
          "🧑🏻‍✈️",
          "🧑🏻‍✈",
          "🧑🏼‍✈️",
          "🧑🏼‍✈",
          "🧑🏽‍✈️",
          "🧑🏽‍✈",
          "🧑🏾‍✈️",
          "🧑🏾‍✈",
          "🧑🏿‍✈️",
          "🧑🏿‍✈",
          "👨‍✈️",
          "👨‍✈",
          "👨🏻‍✈️",
          "👨🏻‍✈",
          "👨🏼‍✈️",
          "👨🏼‍✈",
          "👨🏽‍✈️",
          "👨🏽‍✈",
          "👨🏾‍✈️",
          "👨🏾‍✈",
          "👨🏿‍✈️",
          "👨🏿‍✈",
          "👩‍✈️",
          "👩‍✈",
          "👩🏻‍✈️",
          "👩🏻‍✈",
          "👩🏼‍✈️",
          "👩🏼‍✈",
          "👩🏽‍✈️",
          "👩🏽‍✈",
          "👩🏾‍✈️",
          "👩🏾‍✈",
          "👩🏿‍✈️",
          "👩🏿‍✈",
          "🧑‍🚀",
          "🧑🏻‍🚀",
          "🧑🏼‍🚀",
          "🧑🏽‍🚀",
          "🧑🏾‍🚀",
          "🧑🏿‍🚀",
          "👨‍🚀",
          "👨🏻‍🚀",
          "👨🏼‍🚀",
          "👨🏽‍🚀",
          "👨🏾‍🚀",
          "👨🏿‍🚀",
          "👩‍🚀",
          "👩🏻‍🚀",
          "👩🏼‍🚀",
          "👩🏽‍🚀",
          "👩🏾‍🚀",
          "👩🏿‍🚀",
          "🧑‍🚒",
          "🧑🏻‍🚒",
          "🧑🏼‍🚒",
          "🧑🏽‍🚒",
          "🧑🏾‍🚒",
          "🧑🏿‍🚒",
          "👨‍🚒",
          "👨🏻‍🚒",
          "👨🏼‍🚒",
          "👨🏽‍🚒",
          "👨🏾‍🚒",
          "👨🏿‍🚒",
          "👩‍🚒",
          "👩🏻‍🚒",
          "👩🏼‍🚒",
          "👩🏽‍🚒",
          "👩🏾‍🚒",
          "👩🏿‍🚒",
          "👮",
          "👮🏻",
          "👮🏼",
          "👮🏽",
          "👮🏾",
          "👮🏿",
          "👮‍♂️",
          "👮‍♂",
          "👮🏻‍♂️",
          "👮🏻‍♂",
          "👮🏼‍♂️",
          "👮🏼‍♂",
          "👮🏽‍♂️",
          "👮🏽‍♂",
          "👮🏾‍♂️",
          "👮🏾‍♂",
          "👮🏿‍♂️",
          "👮🏿‍♂",
          "👮‍♀️",
          "👮‍♀",
          "👮🏻‍♀️",
          "👮🏻‍♀",
          "👮🏼‍♀️",
          "👮🏼‍♀",
          "👮🏽‍♀️",
          "👮🏽‍♀",
          "👮🏾‍♀️",
          "👮🏾‍♀",
          "👮🏿‍♀️",
          "👮🏿‍♀",
          "🕵️",
          "🕵",
          "🕵🏻",
          "🕵🏼",
          "🕵🏽",
          "🕵🏾",
          "🕵🏿",
          "🕵️‍♂️",
          "🕵‍♂️",
          "🕵️‍♂",
          "🕵‍♂",
          "🕵🏻‍♂️",
          "🕵🏻‍♂",
          "🕵🏼‍♂️",
          "🕵🏼‍♂",
          "🕵🏽‍♂️",
          "🕵🏽‍♂",
          "🕵🏾‍♂️",
          "🕵🏾‍♂",
          "🕵🏿‍♂️",
          "🕵🏿‍♂",
          "🕵️‍♀️",
          "🕵‍♀️",
          "🕵️‍♀",
          "🕵‍♀",
          "🕵🏻‍♀️",
          "🕵🏻‍♀",
          "🕵🏼‍♀️",
          "🕵🏼‍♀",
          "🕵🏽‍♀️",
          "🕵🏽‍♀",
          "🕵🏾‍♀️",
          "🕵🏾‍♀",
          "🕵🏿‍♀️",
          "🕵🏿‍♀",
          "💂",
          "💂🏻",
          "💂🏼",
          "💂🏽",
          "💂🏾",
          "💂🏿",
          "💂‍♂️",
          "💂‍♂",
          "💂🏻‍♂️",
          "💂🏻‍♂",
          "💂🏼‍♂️",
          "💂🏼‍♂",
          "💂🏽‍♂️",
          "💂🏽‍♂",
          "💂🏾‍♂️",
          "💂🏾‍♂",
          "💂🏿‍♂️",
          "💂🏿‍♂",
          "💂‍♀️",
          "💂‍♀",
          "💂🏻‍♀️",
          "💂🏻‍♀",
          "💂🏼‍♀️",
          "💂🏼‍♀",
          "💂🏽‍♀️",
          "💂🏽‍♀",
          "💂🏾‍♀️",
          "💂🏾‍♀",
          "💂🏿‍♀️",
          "💂🏿‍♀",
          "🥷",
          "🥷🏻",
          "🥷🏼",
          "🥷🏽",
          "🥷🏾",
          "🥷🏿",
          "👷",
          "👷🏻",
          "👷🏼",
          "👷🏽",
          "👷🏾",
          "👷🏿",
          "👷‍♂️",
          "👷‍♂",
          "👷🏻‍♂️",
          "👷🏻‍♂",
          "👷🏼‍♂️",
          "👷🏼‍♂",
          "👷🏽‍♂️",
          "👷🏽‍♂",
          "👷🏾‍♂️",
          "👷🏾‍♂",
          "👷🏿‍♂️",
          "👷🏿‍♂",
          "👷‍♀️",
          "👷‍♀",
          "👷🏻‍♀️",
          "👷🏻‍♀",
          "👷🏼‍♀️",
          "👷🏼‍♀",
          "👷🏽‍♀️",
          "👷🏽‍♀",
          "👷🏾‍♀️",
          "👷🏾‍♀",
          "👷🏿‍♀️",
          "👷🏿‍♀",
          "🫅",
          "🫅🏻",
          "🫅🏼",
          "🫅🏽",
          "🫅🏾",
          "🫅🏿",
          "🤴",
          "🤴🏻",
          "🤴🏼",
          "🤴🏽",
          "🤴🏾",
          "🤴🏿",
          "👸",
          "👸🏻",
          "👸🏼",
          "👸🏽",
          "👸🏾",
          "👸🏿",
          "👳",
          "👳🏻",
          "👳🏼",
          "👳🏽",
          "👳🏾",
          "👳🏿",
          "👳‍♂️",
          "👳‍♂",
          "👳🏻‍♂️",
          "👳🏻‍♂",
          "👳🏼‍♂️",
          "👳🏼‍♂",
          "👳🏽‍♂️",
          "👳🏽‍♂",
          "👳🏾‍♂️",
          "👳🏾‍♂",
          "👳🏿‍♂️",
          "👳🏿‍♂",
          "👳‍♀️",
          "👳‍♀",
          "👳🏻‍♀️",
          "👳🏻‍♀",
          "👳🏼‍♀️",
          "👳🏼‍♀",
          "👳🏽‍♀️",
          "👳🏽‍♀",
          "👳🏾‍♀️",
          "👳🏾‍♀",
          "👳🏿‍♀️",
          "👳🏿‍♀",
          "👲",
          "👲🏻",
          "👲🏼",
          "👲🏽",
          "👲🏾",
          "👲🏿",
          "🧕",
          "🧕🏻",
          "🧕🏼",
          "🧕🏽",
          "🧕🏾",
          "🧕🏿",
          "🤵",
          "🤵🏻",
          "🤵🏼",
          "🤵🏽",
          "🤵🏾",
          "🤵🏿",
          "🤵‍♂️",
          "🤵‍♂",
          "🤵🏻‍♂️",
          "🤵🏻‍♂",
          "🤵🏼‍♂️",
          "🤵🏼‍♂",
          "🤵🏽‍♂️",
          "🤵🏽‍♂",
          "🤵🏾‍♂️",
          "🤵🏾‍♂",
          "🤵🏿‍♂️",
          "🤵🏿‍♂",
          "🤵‍♀️",
          "🤵‍♀",
          "🤵🏻‍♀️",
          "🤵🏻‍♀",
          "🤵🏼‍♀️",
          "🤵🏼‍♀",
          "🤵🏽‍♀️",
          "🤵🏽‍♀",
          "🤵🏾‍♀️",
          "🤵🏾‍♀",
          "🤵🏿‍♀️",
          "🤵🏿‍♀",
          "👰",
          "👰🏻",
          "👰🏼",
          "👰🏽",
          "👰🏾",
          "👰🏿",
          "👰‍♂️",
          "👰‍♂",
          "👰🏻‍♂️",
          "👰🏻‍♂",
          "👰🏼‍♂️",
          "👰🏼‍♂",
          "👰🏽‍♂️",
          "👰🏽‍♂",
          "👰🏾‍♂️",
          "👰🏾‍♂",
          "👰🏿‍♂️",
          "👰🏿‍♂",
          "👰‍♀️",
          "👰‍♀",
          "👰🏻‍♀️",
          "👰🏻‍♀",
          "👰🏼‍♀️",
          "👰🏼‍♀",
          "👰🏽‍♀️",
          "👰🏽‍♀",
          "👰🏾‍♀️",
          "👰🏾‍♀",
          "👰🏿‍♀️",
          "👰🏿‍♀",
          "🤰",
          "🤰🏻",
          "🤰🏼",
          "🤰🏽",
          "🤰🏾",
          "🤰🏿",
          "🫃",
          "🫃🏻",
          "🫃🏼",
          "🫃🏽",
          "🫃🏾",
          "🫃🏿",
          "🫄",
          "🫄🏻",
          "🫄🏼",
          "🫄🏽",
          "🫄🏾",
          "🫄🏿",
          "🤱",
          "🤱🏻",
          "🤱🏼",
          "🤱🏽",
          "🤱🏾",
          "🤱🏿",
          "👩‍🍼",
          "👩🏻‍🍼",
          "👩🏼‍🍼",
          "👩🏽‍🍼",
          "👩🏾‍🍼",
          "👩🏿‍🍼",
          "👨‍🍼",
          "👨🏻‍🍼",
          "👨🏼‍🍼",
          "👨🏽‍🍼",
          "👨🏾‍🍼",
          "👨🏿‍🍼",
          "🧑‍🍼",
          "🧑🏻‍🍼",
          "🧑🏼‍🍼",
          "🧑🏽‍🍼",
          "🧑🏾‍🍼",
          "🧑🏿‍🍼"
      ],
      "person-fantasy": [
          "👼",
          "👼🏻",
          "👼🏼",
          "👼🏽",
          "👼🏾",
          "👼🏿",
          "🎅",
          "🎅🏻",
          "🎅🏼",
          "🎅🏽",
          "🎅🏾",
          "🎅🏿",
          "🤶",
          "🤶🏻",
          "🤶🏼",
          "🤶🏽",
          "🤶🏾",
          "🤶🏿",
          "🧑‍🎄",
          "🧑🏻‍🎄",
          "🧑🏼‍🎄",
          "🧑🏽‍🎄",
          "🧑🏾‍🎄",
          "🧑🏿‍🎄",
          "🦸",
          "🦸🏻",
          "🦸🏼",
          "🦸🏽",
          "🦸🏾",
          "🦸🏿",
          "🦸‍♂️",
          "🦸‍♂",
          "🦸🏻‍♂️",
          "🦸🏻‍♂",
          "🦸🏼‍♂️",
          "🦸🏼‍♂",
          "🦸🏽‍♂️",
          "🦸🏽‍♂",
          "🦸🏾‍♂️",
          "🦸🏾‍♂",
          "🦸🏿‍♂️",
          "🦸🏿‍♂",
          "🦸‍♀️",
          "🦸‍♀",
          "🦸🏻‍♀️",
          "🦸🏻‍♀",
          "🦸🏼‍♀️",
          "🦸🏼‍♀",
          "🦸🏽‍♀️",
          "🦸🏽‍♀",
          "🦸🏾‍♀️",
          "🦸🏾‍♀",
          "🦸🏿‍♀️",
          "🦸🏿‍♀",
          "🦹",
          "🦹🏻",
          "🦹🏼",
          "🦹🏽",
          "🦹🏾",
          "🦹🏿",
          "🦹‍♂️",
          "🦹‍♂",
          "🦹🏻‍♂️",
          "🦹🏻‍♂",
          "🦹🏼‍♂️",
          "🦹🏼‍♂",
          "🦹🏽‍♂️",
          "🦹🏽‍♂",
          "🦹🏾‍♂️",
          "🦹🏾‍♂",
          "🦹🏿‍♂️",
          "🦹🏿‍♂",
          "🦹‍♀️",
          "🦹‍♀",
          "🦹🏻‍♀️",
          "🦹🏻‍♀",
          "🦹🏼‍♀️",
          "🦹🏼‍♀",
          "🦹🏽‍♀️",
          "🦹🏽‍♀",
          "🦹🏾‍♀️",
          "🦹🏾‍♀",
          "🦹🏿‍♀️",
          "🦹🏿‍♀",
          "🧙",
          "🧙🏻",
          "🧙🏼",
          "🧙🏽",
          "🧙🏾",
          "🧙🏿",
          "🧙‍♂️",
          "🧙‍♂",
          "🧙🏻‍♂️",
          "🧙🏻‍♂",
          "🧙🏼‍♂️",
          "🧙🏼‍♂",
          "🧙🏽‍♂️",
          "🧙🏽‍♂",
          "🧙🏾‍♂️",
          "🧙🏾‍♂",
          "🧙🏿‍♂️",
          "🧙🏿‍♂",
          "🧙‍♀️",
          "🧙‍♀",
          "🧙🏻‍♀️",
          "🧙🏻‍♀",
          "🧙🏼‍♀️",
          "🧙🏼‍♀",
          "🧙🏽‍♀️",
          "🧙🏽‍♀",
          "🧙🏾‍♀️",
          "🧙🏾‍♀",
          "🧙🏿‍♀️",
          "🧙🏿‍♀",
          "🧚",
          "🧚🏻",
          "🧚🏼",
          "🧚🏽",
          "🧚🏾",
          "🧚🏿",
          "🧚‍♂️",
          "🧚‍♂",
          "🧚🏻‍♂️",
          "🧚🏻‍♂",
          "🧚🏼‍♂️",
          "🧚🏼‍♂",
          "🧚🏽‍♂️",
          "🧚🏽‍♂",
          "🧚🏾‍♂️",
          "🧚🏾‍♂",
          "🧚🏿‍♂️",
          "🧚🏿‍♂",
          "🧚‍♀️",
          "🧚‍♀",
          "🧚🏻‍♀️",
          "🧚🏻‍♀",
          "🧚🏼‍♀️",
          "🧚🏼‍♀",
          "🧚🏽‍♀️",
          "🧚🏽‍♀",
          "🧚🏾‍♀️",
          "🧚🏾‍♀",
          "🧚🏿‍♀️",
          "🧚🏿‍♀",
          "🧛",
          "🧛🏻",
          "🧛🏼",
          "🧛🏽",
          "🧛🏾",
          "🧛🏿",
          "🧛‍♂️",
          "🧛‍♂",
          "🧛🏻‍♂️",
          "🧛🏻‍♂",
          "🧛🏼‍♂️",
          "🧛🏼‍♂",
          "🧛🏽‍♂️",
          "🧛🏽‍♂",
          "🧛🏾‍♂️",
          "🧛🏾‍♂",
          "🧛🏿‍♂️",
          "🧛🏿‍♂",
          "🧛‍♀️",
          "🧛‍♀",
          "🧛🏻‍♀️",
          "🧛🏻‍♀",
          "🧛🏼‍♀️",
          "🧛🏼‍♀",
          "🧛🏽‍♀️",
          "🧛🏽‍♀",
          "🧛🏾‍♀️",
          "🧛🏾‍♀",
          "🧛🏿‍♀️",
          "🧛🏿‍♀",
          "🧜",
          "🧜🏻",
          "🧜🏼",
          "🧜🏽",
          "🧜🏾",
          "🧜🏿",
          "🧜‍♂️",
          "🧜‍♂",
          "🧜🏻‍♂️",
          "🧜🏻‍♂",
          "🧜🏼‍♂️",
          "🧜🏼‍♂",
          "🧜🏽‍♂️",
          "🧜🏽‍♂",
          "🧜🏾‍♂️",
          "🧜🏾‍♂",
          "🧜🏿‍♂️",
          "🧜🏿‍♂",
          "🧜‍♀️",
          "🧜‍♀",
          "🧜🏻‍♀️",
          "🧜🏻‍♀",
          "🧜🏼‍♀️",
          "🧜🏼‍♀",
          "🧜🏽‍♀️",
          "🧜🏽‍♀",
          "🧜🏾‍♀️",
          "🧜🏾‍♀",
          "🧜🏿‍♀️",
          "🧜🏿‍♀",
          "🧝",
          "🧝🏻",
          "🧝🏼",
          "🧝🏽",
          "🧝🏾",
          "🧝🏿",
          "🧝‍♂️",
          "🧝‍♂",
          "🧝🏻‍♂️",
          "🧝🏻‍♂",
          "🧝🏼‍♂️",
          "🧝🏼‍♂",
          "🧝🏽‍♂️",
          "🧝🏽‍♂",
          "🧝🏾‍♂️",
          "🧝🏾‍♂",
          "🧝🏿‍♂️",
          "🧝🏿‍♂",
          "🧝‍♀️",
          "🧝‍♀",
          "🧝🏻‍♀️",
          "🧝🏻‍♀",
          "🧝🏼‍♀️",
          "🧝🏼‍♀",
          "🧝🏽‍♀️",
          "🧝🏽‍♀",
          "🧝🏾‍♀️",
          "🧝🏾‍♀",
          "🧝🏿‍♀️",
          "🧝🏿‍♀",
          "🧞",
          "🧞‍♂️",
          "🧞‍♂",
          "🧞‍♀️",
          "🧞‍♀",
          "🧟",
          "🧟‍♂️",
          "🧟‍♂",
          "🧟‍♀️",
          "🧟‍♀",
          "🧌"
      ],
      "person-activity": [
          "💆",
          "💆🏻",
          "💆🏼",
          "💆🏽",
          "💆🏾",
          "💆🏿",
          "💆‍♂️",
          "💆‍♂",
          "💆🏻‍♂️",
          "💆🏻‍♂",
          "💆🏼‍♂️",
          "💆🏼‍♂",
          "💆🏽‍♂️",
          "💆🏽‍♂",
          "💆🏾‍♂️",
          "💆🏾‍♂",
          "💆🏿‍♂️",
          "💆🏿‍♂",
          "💆‍♀️",
          "💆‍♀",
          "💆🏻‍♀️",
          "💆🏻‍♀",
          "💆🏼‍♀️",
          "💆🏼‍♀",
          "💆🏽‍♀️",
          "💆🏽‍♀",
          "💆🏾‍♀️",
          "💆🏾‍♀",
          "💆🏿‍♀️",
          "💆🏿‍♀",
          "💇",
          "💇🏻",
          "💇🏼",
          "💇🏽",
          "💇🏾",
          "💇🏿",
          "💇‍♂️",
          "💇‍♂",
          "💇🏻‍♂️",
          "💇🏻‍♂",
          "💇🏼‍♂️",
          "💇🏼‍♂",
          "💇🏽‍♂️",
          "💇🏽‍♂",
          "💇🏾‍♂️",
          "💇🏾‍♂",
          "💇🏿‍♂️",
          "💇🏿‍♂",
          "💇‍♀️",
          "💇‍♀",
          "💇🏻‍♀️",
          "💇🏻‍♀",
          "💇🏼‍♀️",
          "💇🏼‍♀",
          "💇🏽‍♀️",
          "💇🏽‍♀",
          "💇🏾‍♀️",
          "💇🏾‍♀",
          "💇🏿‍♀️",
          "💇🏿‍♀",
          "🚶",
          "🚶🏻",
          "🚶🏼",
          "🚶🏽",
          "🚶🏾",
          "🚶🏿",
          "🚶‍♂️",
          "🚶‍♂",
          "🚶🏻‍♂️",
          "🚶🏻‍♂",
          "🚶🏼‍♂️",
          "🚶🏼‍♂",
          "🚶🏽‍♂️",
          "🚶🏽‍♂",
          "🚶🏾‍♂️",
          "🚶🏾‍♂",
          "🚶🏿‍♂️",
          "🚶🏿‍♂",
          "🚶‍♀️",
          "🚶‍♀",
          "🚶🏻‍♀️",
          "🚶🏻‍♀",
          "🚶🏼‍♀️",
          "🚶🏼‍♀",
          "🚶🏽‍♀️",
          "🚶🏽‍♀",
          "🚶🏾‍♀️",
          "🚶🏾‍♀",
          "🚶🏿‍♀️",
          "🚶🏿‍♀",
          "🚶‍➡️",
          "🚶‍➡",
          "🚶🏻‍➡️",
          "🚶🏻‍➡",
          "🚶🏼‍➡️",
          "🚶🏼‍➡",
          "🚶🏽‍➡️",
          "🚶🏽‍➡",
          "🚶🏾‍➡️",
          "🚶🏾‍➡",
          "🚶🏿‍➡️",
          "🚶🏿‍➡",
          "🚶‍♀️‍➡️",
          "🚶‍♀‍➡️",
          "🚶‍♀️‍➡",
          "🚶‍♀‍➡",
          "🚶🏻‍♀️‍➡️",
          "🚶🏻‍♀‍➡️",
          "🚶🏻‍♀️‍➡",
          "🚶🏻‍♀‍➡",
          "🚶🏼‍♀️‍➡️",
          "🚶🏼‍♀‍➡️",
          "🚶🏼‍♀️‍➡",
          "🚶🏼‍♀‍➡",
          "🚶🏽‍♀️‍➡️",
          "🚶🏽‍♀‍➡️",
          "🚶🏽‍♀️‍➡",
          "🚶🏽‍♀‍➡",
          "🚶🏾‍♀️‍➡️",
          "🚶🏾‍♀‍➡️",
          "🚶🏾‍♀️‍➡",
          "🚶🏾‍♀‍➡",
          "🚶🏿‍♀️‍➡️",
          "🚶🏿‍♀‍➡️",
          "🚶🏿‍♀️‍➡",
          "🚶🏿‍♀‍➡",
          "🚶‍♂️‍➡️",
          "🚶‍♂‍➡️",
          "🚶‍♂️‍➡",
          "🚶‍♂‍➡",
          "🚶🏻‍♂️‍➡️",
          "🚶🏻‍♂‍➡️",
          "🚶🏻‍♂️‍➡",
          "🚶🏻‍♂‍➡",
          "🚶🏼‍♂️‍➡️",
          "🚶🏼‍♂‍➡️",
          "🚶🏼‍♂️‍➡",
          "🚶🏼‍♂‍➡",
          "🚶🏽‍♂️‍➡️",
          "🚶🏽‍♂‍➡️",
          "🚶🏽‍♂️‍➡",
          "🚶🏽‍♂‍➡",
          "🚶🏾‍♂️‍➡️",
          "🚶🏾‍♂‍➡️",
          "🚶🏾‍♂️‍➡",
          "🚶🏾‍♂‍➡",
          "🚶🏿‍♂️‍➡️",
          "🚶🏿‍♂‍➡️",
          "🚶🏿‍♂️‍➡",
          "🚶🏿‍♂‍➡",
          "🧍",
          "🧍🏻",
          "🧍🏼",
          "🧍🏽",
          "🧍🏾",
          "🧍🏿",
          "🧍‍♂️",
          "🧍‍♂",
          "🧍🏻‍♂️",
          "🧍🏻‍♂",
          "🧍🏼‍♂️",
          "🧍🏼‍♂",
          "🧍🏽‍♂️",
          "🧍🏽‍♂",
          "🧍🏾‍♂️",
          "🧍🏾‍♂",
          "🧍🏿‍♂️",
          "🧍🏿‍♂",
          "🧍‍♀️",
          "🧍‍♀",
          "🧍🏻‍♀️",
          "🧍🏻‍♀",
          "🧍🏼‍♀️",
          "🧍🏼‍♀",
          "🧍🏽‍♀️",
          "🧍🏽‍♀",
          "🧍🏾‍♀️",
          "🧍🏾‍♀",
          "🧍🏿‍♀️",
          "🧍🏿‍♀",
          "🧎",
          "🧎🏻",
          "🧎🏼",
          "🧎🏽",
          "🧎🏾",
          "🧎🏿",
          "🧎‍♂️",
          "🧎‍♂",
          "🧎🏻‍♂️",
          "🧎🏻‍♂",
          "🧎🏼‍♂️",
          "🧎🏼‍♂",
          "🧎🏽‍♂️",
          "🧎🏽‍♂",
          "🧎🏾‍♂️",
          "🧎🏾‍♂",
          "🧎🏿‍♂️",
          "🧎🏿‍♂",
          "🧎‍♀️",
          "🧎‍♀",
          "🧎🏻‍♀️",
          "🧎🏻‍♀",
          "🧎🏼‍♀️",
          "🧎🏼‍♀",
          "🧎🏽‍♀️",
          "🧎🏽‍♀",
          "🧎🏾‍♀️",
          "🧎🏾‍♀",
          "🧎🏿‍♀️",
          "🧎🏿‍♀",
          "🧎‍➡️",
          "🧎‍➡",
          "🧎🏻‍➡️",
          "🧎🏻‍➡",
          "🧎🏼‍➡️",
          "🧎🏼‍➡",
          "🧎🏽‍➡️",
          "🧎🏽‍➡",
          "🧎🏾‍➡️",
          "🧎🏾‍➡",
          "🧎🏿‍➡️",
          "🧎🏿‍➡",
          "🧎‍♀️‍➡️",
          "🧎‍♀‍➡️",
          "🧎‍♀️‍➡",
          "🧎‍♀‍➡",
          "🧎🏻‍♀️‍➡️",
          "🧎🏻‍♀‍➡️",
          "🧎🏻‍♀️‍➡",
          "🧎🏻‍♀‍➡",
          "🧎🏼‍♀️‍➡️",
          "🧎🏼‍♀‍➡️",
          "🧎🏼‍♀️‍➡",
          "🧎🏼‍♀‍➡",
          "🧎🏽‍♀️‍➡️",
          "🧎🏽‍♀‍➡️",
          "🧎🏽‍♀️‍➡",
          "🧎🏽‍♀‍➡",
          "🧎🏾‍♀️‍➡️",
          "🧎🏾‍♀‍➡️",
          "🧎🏾‍♀️‍➡",
          "🧎🏾‍♀‍➡",
          "🧎🏿‍♀️‍➡️",
          "🧎🏿‍♀‍➡️",
          "🧎🏿‍♀️‍➡",
          "🧎🏿‍♀‍➡",
          "🧎‍♂️‍➡️",
          "🧎‍♂‍➡️",
          "🧎‍♂️‍➡",
          "🧎‍♂‍➡",
          "🧎🏻‍♂️‍➡️",
          "🧎🏻‍♂‍➡️",
          "🧎🏻‍♂️‍➡",
          "🧎🏻‍♂‍➡",
          "🧎🏼‍♂️‍➡️",
          "🧎🏼‍♂‍➡️",
          "🧎🏼‍♂️‍➡",
          "🧎🏼‍♂‍➡",
          "🧎🏽‍♂️‍➡️",
          "🧎🏽‍♂‍➡️",
          "🧎🏽‍♂️‍➡",
          "🧎🏽‍♂‍➡",
          "🧎🏾‍♂️‍➡️",
          "🧎🏾‍♂‍➡️",
          "🧎🏾‍♂️‍➡",
          "🧎🏾‍♂‍➡",
          "🧎🏿‍♂️‍➡️",
          "🧎🏿‍♂‍➡️",
          "🧎🏿‍♂️‍➡",
          "🧎🏿‍♂‍➡",
          "🧑‍🦯",
          "🧑🏻‍🦯",
          "🧑🏼‍🦯",
          "🧑🏽‍🦯",
          "🧑🏾‍🦯",
          "🧑🏿‍🦯",
          "🧑‍🦯‍➡️",
          "🧑‍🦯‍➡",
          "🧑🏻‍🦯‍➡️",
          "🧑🏻‍🦯‍➡",
          "🧑🏼‍🦯‍➡️",
          "🧑🏼‍🦯‍➡",
          "🧑🏽‍🦯‍➡️",
          "🧑🏽‍🦯‍➡",
          "🧑🏾‍🦯‍➡️",
          "🧑🏾‍🦯‍➡",
          "🧑🏿‍🦯‍➡️",
          "🧑🏿‍🦯‍➡",
          "👨‍🦯",
          "👨🏻‍🦯",
          "👨🏼‍🦯",
          "👨🏽‍🦯",
          "👨🏾‍🦯",
          "👨🏿‍🦯",
          "👨‍🦯‍➡️",
          "👨‍🦯‍➡",
          "👨🏻‍🦯‍➡️",
          "👨🏻‍🦯‍➡",
          "👨🏼‍🦯‍➡️",
          "👨🏼‍🦯‍➡",
          "👨🏽‍🦯‍➡️",
          "👨🏽‍🦯‍➡",
          "👨🏾‍🦯‍➡️",
          "👨🏾‍🦯‍➡",
          "👨🏿‍🦯‍➡️",
          "👨🏿‍🦯‍➡",
          "👩‍🦯",
          "👩🏻‍🦯",
          "👩🏼‍🦯",
          "👩🏽‍🦯",
          "👩🏾‍🦯",
          "👩🏿‍🦯",
          "👩‍🦯‍➡️",
          "👩‍🦯‍➡",
          "👩🏻‍🦯‍➡️",
          "👩🏻‍🦯‍➡",
          "👩🏼‍🦯‍➡️",
          "👩🏼‍🦯‍➡",
          "👩🏽‍🦯‍➡️",
          "👩🏽‍🦯‍➡",
          "👩🏾‍🦯‍➡️",
          "👩🏾‍🦯‍➡",
          "👩🏿‍🦯‍➡️",
          "👩🏿‍🦯‍➡",
          "🧑‍🦼",
          "🧑🏻‍🦼",
          "🧑🏼‍🦼",
          "🧑🏽‍🦼",
          "🧑🏾‍🦼",
          "🧑🏿‍🦼",
          "🧑‍🦼‍➡️",
          "🧑‍🦼‍➡",
          "🧑🏻‍🦼‍➡️",
          "🧑🏻‍🦼‍➡",
          "🧑🏼‍🦼‍➡️",
          "🧑🏼‍🦼‍➡",
          "🧑🏽‍🦼‍➡️",
          "🧑🏽‍🦼‍➡",
          "🧑🏾‍🦼‍➡️",
          "🧑🏾‍🦼‍➡",
          "🧑🏿‍🦼‍➡️",
          "🧑🏿‍🦼‍➡",
          "👨‍🦼",
          "👨🏻‍🦼",
          "👨🏼‍🦼",
          "👨🏽‍🦼",
          "👨🏾‍🦼",
          "👨🏿‍🦼",
          "👨‍🦼‍➡️",
          "👨‍🦼‍➡",
          "👨🏻‍🦼‍➡️",
          "👨🏻‍🦼‍➡",
          "👨🏼‍🦼‍➡️",
          "👨🏼‍🦼‍➡",
          "👨🏽‍🦼‍➡️",
          "👨🏽‍🦼‍➡",
          "👨🏾‍🦼‍➡️",
          "👨🏾‍🦼‍➡",
          "👨🏿‍🦼‍➡️",
          "👨🏿‍🦼‍➡",
          "👩‍🦼",
          "👩🏻‍🦼",
          "👩🏼‍🦼",
          "👩🏽‍🦼",
          "👩🏾‍🦼",
          "👩🏿‍🦼",
          "👩‍🦼‍➡️",
          "👩‍🦼‍➡",
          "👩🏻‍🦼‍➡️",
          "👩🏻‍🦼‍➡",
          "👩🏼‍🦼‍➡️",
          "👩🏼‍🦼‍➡",
          "👩🏽‍🦼‍➡️",
          "👩🏽‍🦼‍➡",
          "👩🏾‍🦼‍➡️",
          "👩🏾‍🦼‍➡",
          "👩🏿‍🦼‍➡️",
          "👩🏿‍🦼‍➡",
          "🧑‍🦽",
          "🧑🏻‍🦽",
          "🧑🏼‍🦽",
          "🧑🏽‍🦽",
          "🧑🏾‍🦽",
          "🧑🏿‍🦽",
          "🧑‍🦽‍➡️",
          "🧑‍🦽‍➡",
          "🧑🏻‍🦽‍➡️",
          "🧑🏻‍🦽‍➡",
          "🧑🏼‍🦽‍➡️",
          "🧑🏼‍🦽‍➡",
          "🧑🏽‍🦽‍➡️",
          "🧑🏽‍🦽‍➡",
          "🧑🏾‍🦽‍➡️",
          "🧑🏾‍🦽‍➡",
          "🧑🏿‍🦽‍➡️",
          "🧑🏿‍🦽‍➡",
          "👨‍🦽",
          "👨🏻‍🦽",
          "👨🏼‍🦽",
          "👨🏽‍🦽",
          "👨🏾‍🦽",
          "👨🏿‍🦽",
          "👨‍🦽‍➡️",
          "👨‍🦽‍➡",
          "👨🏻‍🦽‍➡️",
          "👨🏻‍🦽‍➡",
          "👨🏼‍🦽‍➡️",
          "👨🏼‍🦽‍➡",
          "👨🏽‍🦽‍➡️",
          "👨🏽‍🦽‍➡",
          "👨🏾‍🦽‍➡️",
          "👨🏾‍🦽‍➡",
          "👨🏿‍🦽‍➡️",
          "👨🏿‍🦽‍➡",
          "👩‍🦽",
          "👩🏻‍🦽",
          "👩🏼‍🦽",
          "👩🏽‍🦽",
          "👩🏾‍🦽",
          "👩🏿‍🦽",
          "👩‍🦽‍➡️",
          "👩‍🦽‍➡",
          "👩🏻‍🦽‍➡️",
          "👩🏻‍🦽‍➡",
          "👩🏼‍🦽‍➡️",
          "👩🏼‍🦽‍➡",
          "👩🏽‍🦽‍➡️",
          "👩🏽‍🦽‍➡",
          "👩🏾‍🦽‍➡️",
          "👩🏾‍🦽‍➡",
          "👩🏿‍🦽‍➡️",
          "👩🏿‍🦽‍➡",
          "🏃",
          "🏃🏻",
          "🏃🏼",
          "🏃🏽",
          "🏃🏾",
          "🏃🏿",
          "🏃‍♂️",
          "🏃‍♂",
          "🏃🏻‍♂️",
          "🏃🏻‍♂",
          "🏃🏼‍♂️",
          "🏃🏼‍♂",
          "🏃🏽‍♂️",
          "🏃🏽‍♂",
          "🏃🏾‍♂️",
          "🏃🏾‍♂",
          "🏃🏿‍♂️",
          "🏃🏿‍♂",
          "🏃‍♀️",
          "🏃‍♀",
          "🏃🏻‍♀️",
          "🏃🏻‍♀",
          "🏃🏼‍♀️",
          "🏃🏼‍♀",
          "🏃🏽‍♀️",
          "🏃🏽‍♀",
          "🏃🏾‍♀️",
          "🏃🏾‍♀",
          "🏃🏿‍♀️",
          "🏃🏿‍♀",
          "🏃‍➡️",
          "🏃‍➡",
          "🏃🏻‍➡️",
          "🏃🏻‍➡",
          "🏃🏼‍➡️",
          "🏃🏼‍➡",
          "🏃🏽‍➡️",
          "🏃🏽‍➡",
          "🏃🏾‍➡️",
          "🏃🏾‍➡",
          "🏃🏿‍➡️",
          "🏃🏿‍➡",
          "🏃‍♀️‍➡️",
          "🏃‍♀‍➡️",
          "🏃‍♀️‍➡",
          "🏃‍♀‍➡",
          "🏃🏻‍♀️‍➡️",
          "🏃🏻‍♀‍➡️",
          "🏃🏻‍♀️‍➡",
          "🏃🏻‍♀‍➡",
          "🏃🏼‍♀️‍➡️",
          "🏃🏼‍♀‍➡️",
          "🏃🏼‍♀️‍➡",
          "🏃🏼‍♀‍➡",
          "🏃🏽‍♀️‍➡️",
          "🏃🏽‍♀‍➡️",
          "🏃🏽‍♀️‍➡",
          "🏃🏽‍♀‍➡",
          "🏃🏾‍♀️‍➡️",
          "🏃🏾‍♀‍➡️",
          "🏃🏾‍♀️‍➡",
          "🏃🏾‍♀‍➡",
          "🏃🏿‍♀️‍➡️",
          "🏃🏿‍♀‍➡️",
          "🏃🏿‍♀️‍➡",
          "🏃🏿‍♀‍➡",
          "🏃‍♂️‍➡️",
          "🏃‍♂‍➡️",
          "🏃‍♂️‍➡",
          "🏃‍♂‍➡",
          "🏃🏻‍♂️‍➡️",
          "🏃🏻‍♂‍➡️",
          "🏃🏻‍♂️‍➡",
          "🏃🏻‍♂‍➡",
          "🏃🏼‍♂️‍➡️",
          "🏃🏼‍♂‍➡️",
          "🏃🏼‍♂️‍➡",
          "🏃🏼‍♂‍➡",
          "🏃🏽‍♂️‍➡️",
          "🏃🏽‍♂‍➡️",
          "🏃🏽‍♂️‍➡",
          "🏃🏽‍♂‍➡",
          "🏃🏾‍♂️‍➡️",
          "🏃🏾‍♂‍➡️",
          "🏃🏾‍♂️‍➡",
          "🏃🏾‍♂‍➡",
          "🏃🏿‍♂️‍➡️",
          "🏃🏿‍♂‍➡️",
          "🏃🏿‍♂️‍➡",
          "🏃🏿‍♂‍➡",
          "💃",
          "💃🏻",
          "💃🏼",
          "💃🏽",
          "💃🏾",
          "💃🏿",
          "🕺",
          "🕺🏻",
          "🕺🏼",
          "🕺🏽",
          "🕺🏾",
          "🕺🏿",
          "🕴️",
          "🕴",
          "🕴🏻",
          "🕴🏼",
          "🕴🏽",
          "🕴🏾",
          "🕴🏿",
          "👯",
          "👯‍♂️",
          "👯‍♂",
          "👯‍♀️",
          "👯‍♀",
          "🧖",
          "🧖🏻",
          "🧖🏼",
          "🧖🏽",
          "🧖🏾",
          "🧖🏿",
          "🧖‍♂️",
          "🧖‍♂",
          "🧖🏻‍♂️",
          "🧖🏻‍♂",
          "🧖🏼‍♂️",
          "🧖🏼‍♂",
          "🧖🏽‍♂️",
          "🧖🏽‍♂",
          "🧖🏾‍♂️",
          "🧖🏾‍♂",
          "🧖🏿‍♂️",
          "🧖🏿‍♂",
          "🧖‍♀️",
          "🧖‍♀",
          "🧖🏻‍♀️",
          "🧖🏻‍♀",
          "🧖🏼‍♀️",
          "🧖🏼‍♀",
          "🧖🏽‍♀️",
          "🧖🏽‍♀",
          "🧖🏾‍♀️",
          "🧖🏾‍♀",
          "🧖🏿‍♀️",
          "🧖🏿‍♀",
          "🧗",
          "🧗🏻",
          "🧗🏼",
          "🧗🏽",
          "🧗🏾",
          "🧗🏿",
          "🧗‍♂️",
          "🧗‍♂",
          "🧗🏻‍♂️",
          "🧗🏻‍♂",
          "🧗🏼‍♂️",
          "🧗🏼‍♂",
          "🧗🏽‍♂️",
          "🧗🏽‍♂",
          "🧗🏾‍♂️",
          "🧗🏾‍♂",
          "🧗🏿‍♂️",
          "🧗🏿‍♂",
          "🧗‍♀️",
          "🧗‍♀",
          "🧗🏻‍♀️",
          "🧗🏻‍♀",
          "🧗🏼‍♀️",
          "🧗🏼‍♀",
          "🧗🏽‍♀️",
          "🧗🏽‍♀",
          "🧗🏾‍♀️",
          "🧗🏾‍♀",
          "🧗🏿‍♀️",
          "🧗🏿‍♀"
      ],
      "person-sport": [
          "🤺",
          "🏇",
          "🏇🏻",
          "🏇🏼",
          "🏇🏽",
          "🏇🏾",
          "🏇🏿",
          "⛷️",
          "⛷",
          "🏂",
          "🏂🏻",
          "🏂🏼",
          "🏂🏽",
          "🏂🏾",
          "🏂🏿",
          "🏌️",
          "🏌",
          "🏌🏻",
          "🏌🏼",
          "🏌🏽",
          "🏌🏾",
          "🏌🏿",
          "🏌️‍♂️",
          "🏌‍♂️",
          "🏌️‍♂",
          "🏌‍♂",
          "🏌🏻‍♂️",
          "🏌🏻‍♂",
          "🏌🏼‍♂️",
          "🏌🏼‍♂",
          "🏌🏽‍♂️",
          "🏌🏽‍♂",
          "🏌🏾‍♂️",
          "🏌🏾‍♂",
          "🏌🏿‍♂️",
          "🏌🏿‍♂",
          "🏌️‍♀️",
          "🏌‍♀️",
          "🏌️‍♀",
          "🏌‍♀",
          "🏌🏻‍♀️",
          "🏌🏻‍♀",
          "🏌🏼‍♀️",
          "🏌🏼‍♀",
          "🏌🏽‍♀️",
          "🏌🏽‍♀",
          "🏌🏾‍♀️",
          "🏌🏾‍♀",
          "🏌🏿‍♀️",
          "🏌🏿‍♀",
          "🏄",
          "🏄🏻",
          "🏄🏼",
          "🏄🏽",
          "🏄🏾",
          "🏄🏿",
          "🏄‍♂️",
          "🏄‍♂",
          "🏄🏻‍♂️",
          "🏄🏻‍♂",
          "🏄🏼‍♂️",
          "🏄🏼‍♂",
          "🏄🏽‍♂️",
          "🏄🏽‍♂",
          "🏄🏾‍♂️",
          "🏄🏾‍♂",
          "🏄🏿‍♂️",
          "🏄🏿‍♂",
          "🏄‍♀️",
          "🏄‍♀",
          "🏄🏻‍♀️",
          "🏄🏻‍♀",
          "🏄🏼‍♀️",
          "🏄🏼‍♀",
          "🏄🏽‍♀️",
          "🏄🏽‍♀",
          "🏄🏾‍♀️",
          "🏄🏾‍♀",
          "🏄🏿‍♀️",
          "🏄🏿‍♀",
          "🚣",
          "🚣🏻",
          "🚣🏼",
          "🚣🏽",
          "🚣🏾",
          "🚣🏿",
          "🚣‍♂️",
          "🚣‍♂",
          "🚣🏻‍♂️",
          "🚣🏻‍♂",
          "🚣🏼‍♂️",
          "🚣🏼‍♂",
          "🚣🏽‍♂️",
          "🚣🏽‍♂",
          "🚣🏾‍♂️",
          "🚣🏾‍♂",
          "🚣🏿‍♂️",
          "🚣🏿‍♂",
          "🚣‍♀️",
          "🚣‍♀",
          "🚣🏻‍♀️",
          "🚣🏻‍♀",
          "🚣🏼‍♀️",
          "🚣🏼‍♀",
          "🚣🏽‍♀️",
          "🚣🏽‍♀",
          "🚣🏾‍♀️",
          "🚣🏾‍♀",
          "🚣🏿‍♀️",
          "🚣🏿‍♀",
          "🏊",
          "🏊🏻",
          "🏊🏼",
          "🏊🏽",
          "🏊🏾",
          "🏊🏿",
          "🏊‍♂️",
          "🏊‍♂",
          "🏊🏻‍♂️",
          "🏊🏻‍♂",
          "🏊🏼‍♂️",
          "🏊🏼‍♂",
          "🏊🏽‍♂️",
          "🏊🏽‍♂",
          "🏊🏾‍♂️",
          "🏊🏾‍♂",
          "🏊🏿‍♂️",
          "🏊🏿‍♂",
          "🏊‍♀️",
          "🏊‍♀",
          "🏊🏻‍♀️",
          "🏊🏻‍♀",
          "🏊🏼‍♀️",
          "🏊🏼‍♀",
          "🏊🏽‍♀️",
          "🏊🏽‍♀",
          "🏊🏾‍♀️",
          "🏊🏾‍♀",
          "🏊🏿‍♀️",
          "🏊🏿‍♀",
          "⛹️",
          "⛹",
          "⛹🏻",
          "⛹🏼",
          "⛹🏽",
          "⛹🏾",
          "⛹🏿",
          "⛹️‍♂️",
          "⛹‍♂️",
          "⛹️‍♂",
          "⛹‍♂",
          "⛹🏻‍♂️",
          "⛹🏻‍♂",
          "⛹🏼‍♂️",
          "⛹🏼‍♂",
          "⛹🏽‍♂️",
          "⛹🏽‍♂",
          "⛹🏾‍♂️",
          "⛹🏾‍♂",
          "⛹🏿‍♂️",
          "⛹🏿‍♂",
          "⛹️‍♀️",
          "⛹‍♀️",
          "⛹️‍♀",
          "⛹‍♀",
          "⛹🏻‍♀️",
          "⛹🏻‍♀",
          "⛹🏼‍♀️",
          "⛹🏼‍♀",
          "⛹🏽‍♀️",
          "⛹🏽‍♀",
          "⛹🏾‍♀️",
          "⛹🏾‍♀",
          "⛹🏿‍♀️",
          "⛹🏿‍♀",
          "🏋️",
          "🏋",
          "🏋🏻",
          "🏋🏼",
          "🏋🏽",
          "🏋🏾",
          "🏋🏿",
          "🏋️‍♂️",
          "🏋‍♂️",
          "🏋️‍♂",
          "🏋‍♂",
          "🏋🏻‍♂️",
          "🏋🏻‍♂",
          "🏋🏼‍♂️",
          "🏋🏼‍♂",
          "🏋🏽‍♂️",
          "🏋🏽‍♂",
          "🏋🏾‍♂️",
          "🏋🏾‍♂",
          "🏋🏿‍♂️",
          "🏋🏿‍♂",
          "🏋️‍♀️",
          "🏋‍♀️",
          "🏋️‍♀",
          "🏋‍♀",
          "🏋🏻‍♀️",
          "🏋🏻‍♀",
          "🏋🏼‍♀️",
          "🏋🏼‍♀",
          "🏋🏽‍♀️",
          "🏋🏽‍♀",
          "🏋🏾‍♀️",
          "🏋🏾‍♀",
          "🏋🏿‍♀️",
          "🏋🏿‍♀",
          "🚴",
          "🚴🏻",
          "🚴🏼",
          "🚴🏽",
          "🚴🏾",
          "🚴🏿",
          "🚴‍♂️",
          "🚴‍♂",
          "🚴🏻‍♂️",
          "🚴🏻‍♂",
          "🚴🏼‍♂️",
          "🚴🏼‍♂",
          "🚴🏽‍♂️",
          "🚴🏽‍♂",
          "🚴🏾‍♂️",
          "🚴🏾‍♂",
          "🚴🏿‍♂️",
          "🚴🏿‍♂",
          "🚴‍♀️",
          "🚴‍♀",
          "🚴🏻‍♀️",
          "🚴🏻‍♀",
          "🚴🏼‍♀️",
          "🚴🏼‍♀",
          "🚴🏽‍♀️",
          "🚴🏽‍♀",
          "🚴🏾‍♀️",
          "🚴🏾‍♀",
          "🚴🏿‍♀️",
          "🚴🏿‍♀",
          "🚵",
          "🚵🏻",
          "🚵🏼",
          "🚵🏽",
          "🚵🏾",
          "🚵🏿",
          "🚵‍♂️",
          "🚵‍♂",
          "🚵🏻‍♂️",
          "🚵🏻‍♂",
          "🚵🏼‍♂️",
          "🚵🏼‍♂",
          "🚵🏽‍♂️",
          "🚵🏽‍♂",
          "🚵🏾‍♂️",
          "🚵🏾‍♂",
          "🚵🏿‍♂️",
          "🚵🏿‍♂",
          "🚵‍♀️",
          "🚵‍♀",
          "🚵🏻‍♀️",
          "🚵🏻‍♀",
          "🚵🏼‍♀️",
          "🚵🏼‍♀",
          "🚵🏽‍♀️",
          "🚵🏽‍♀",
          "🚵🏾‍♀️",
          "🚵🏾‍♀",
          "🚵🏿‍♀️",
          "🚵🏿‍♀",
          "🤸",
          "🤸🏻",
          "🤸🏼",
          "🤸🏽",
          "🤸🏾",
          "🤸🏿",
          "🤸‍♂️",
          "🤸‍♂",
          "🤸🏻‍♂️",
          "🤸🏻‍♂",
          "🤸🏼‍♂️",
          "🤸🏼‍♂",
          "🤸🏽‍♂️",
          "🤸🏽‍♂",
          "🤸🏾‍♂️",
          "🤸🏾‍♂",
          "🤸🏿‍♂️",
          "🤸🏿‍♂",
          "🤸‍♀️",
          "🤸‍♀",
          "🤸🏻‍♀️",
          "🤸🏻‍♀",
          "🤸🏼‍♀️",
          "🤸🏼‍♀",
          "🤸🏽‍♀️",
          "🤸🏽‍♀",
          "🤸🏾‍♀️",
          "🤸🏾‍♀",
          "🤸🏿‍♀️",
          "🤸🏿‍♀",
          "🤼",
          "🤼‍♂️",
          "🤼‍♂",
          "🤼‍♀️",
          "🤼‍♀",
          "🤽",
          "🤽🏻",
          "🤽🏼",
          "🤽🏽",
          "🤽🏾",
          "🤽🏿",
          "🤽‍♂️",
          "🤽‍♂",
          "🤽🏻‍♂️",
          "🤽🏻‍♂",
          "🤽🏼‍♂️",
          "🤽🏼‍♂",
          "🤽🏽‍♂️",
          "🤽🏽‍♂",
          "🤽🏾‍♂️",
          "🤽🏾‍♂",
          "🤽🏿‍♂️",
          "🤽🏿‍♂",
          "🤽‍♀️",
          "🤽‍♀",
          "🤽🏻‍♀️",
          "🤽🏻‍♀",
          "🤽🏼‍♀️",
          "🤽🏼‍♀",
          "🤽🏽‍♀️",
          "🤽🏽‍♀",
          "🤽🏾‍♀️",
          "🤽🏾‍♀",
          "🤽🏿‍♀️",
          "🤽🏿‍♀",
          "🤾",
          "🤾🏻",
          "🤾🏼",
          "🤾🏽",
          "🤾🏾",
          "🤾🏿",
          "🤾‍♂️",
          "🤾‍♂",
          "🤾🏻‍♂️",
          "🤾🏻‍♂",
          "🤾🏼‍♂️",
          "🤾🏼‍♂",
          "🤾🏽‍♂️",
          "🤾🏽‍♂",
          "🤾🏾‍♂️",
          "🤾🏾‍♂",
          "🤾🏿‍♂️",
          "🤾🏿‍♂",
          "🤾‍♀️",
          "🤾‍♀",
          "🤾🏻‍♀️",
          "🤾🏻‍♀",
          "🤾🏼‍♀️",
          "🤾🏼‍♀",
          "🤾🏽‍♀️",
          "🤾🏽‍♀",
          "🤾🏾‍♀️",
          "🤾🏾‍♀",
          "🤾🏿‍♀️",
          "🤾🏿‍♀",
          "🤹",
          "🤹🏻",
          "🤹🏼",
          "🤹🏽",
          "🤹🏾",
          "🤹🏿",
          "🤹‍♂️",
          "🤹‍♂",
          "🤹🏻‍♂️",
          "🤹🏻‍♂",
          "🤹🏼‍♂️",
          "🤹🏼‍♂",
          "🤹🏽‍♂️",
          "🤹🏽‍♂",
          "🤹🏾‍♂️",
          "🤹🏾‍♂",
          "🤹🏿‍♂️",
          "🤹🏿‍♂",
          "🤹‍♀️",
          "🤹‍♀",
          "🤹🏻‍♀️",
          "🤹🏻‍♀",
          "🤹🏼‍♀️",
          "🤹🏼‍♀",
          "🤹🏽‍♀️",
          "🤹🏽‍♀",
          "🤹🏾‍♀️",
          "🤹🏾‍♀",
          "🤹🏿‍♀️",
          "🤹🏿‍♀"
      ],
      "person-resting": [
          "🧘",
          "🧘🏻",
          "🧘🏼",
          "🧘🏽",
          "🧘🏾",
          "🧘🏿",
          "🧘‍♂️",
          "🧘‍♂",
          "🧘🏻‍♂️",
          "🧘🏻‍♂",
          "🧘🏼‍♂️",
          "🧘🏼‍♂",
          "🧘🏽‍♂️",
          "🧘🏽‍♂",
          "🧘🏾‍♂️",
          "🧘🏾‍♂",
          "🧘🏿‍♂️",
          "🧘🏿‍♂",
          "🧘‍♀️",
          "🧘‍♀",
          "🧘🏻‍♀️",
          "🧘🏻‍♀",
          "🧘🏼‍♀️",
          "🧘🏼‍♀",
          "🧘🏽‍♀️",
          "🧘🏽‍♀",
          "🧘🏾‍♀️",
          "🧘🏾‍♀",
          "🧘🏿‍♀️",
          "🧘🏿‍♀",
          "🛀",
          "🛀🏻",
          "🛀🏼",
          "🛀🏽",
          "🛀🏾",
          "🛀🏿",
          "🛌",
          "🛌🏻",
          "🛌🏼",
          "🛌🏽",
          "🛌🏾",
          "🛌🏿"
      ],
      "family": [
          "🧑‍🤝‍🧑",
          "🧑🏻‍🤝‍🧑🏻",
          "🧑🏻‍🤝‍🧑🏼",
          "🧑🏻‍🤝‍🧑🏽",
          "🧑🏻‍🤝‍🧑🏾",
          "🧑🏻‍🤝‍🧑🏿",
          "🧑🏼‍🤝‍🧑🏻",
          "🧑🏼‍🤝‍🧑🏼",
          "🧑🏼‍🤝‍🧑🏽",
          "🧑🏼‍🤝‍🧑🏾",
          "🧑🏼‍🤝‍🧑🏿",
          "🧑🏽‍🤝‍🧑🏻",
          "🧑🏽‍🤝‍🧑🏼",
          "🧑🏽‍🤝‍🧑🏽",
          "🧑🏽‍🤝‍🧑🏾",
          "🧑🏽‍🤝‍🧑🏿",
          "🧑🏾‍🤝‍🧑🏻",
          "🧑🏾‍🤝‍🧑🏼",
          "🧑🏾‍🤝‍🧑🏽",
          "🧑🏾‍🤝‍🧑🏾",
          "🧑🏾‍🤝‍🧑🏿",
          "🧑🏿‍🤝‍🧑🏻",
          "🧑🏿‍🤝‍🧑🏼",
          "🧑🏿‍🤝‍🧑🏽",
          "🧑🏿‍🤝‍🧑🏾",
          "🧑🏿‍🤝‍🧑🏿",
          "👭",
          "👭🏻",
          "👩🏻‍🤝‍👩🏼",
          "👩🏻‍🤝‍👩🏽",
          "👩🏻‍🤝‍👩🏾",
          "👩🏻‍🤝‍👩🏿",
          "👩🏼‍🤝‍👩🏻",
          "👭🏼",
          "👩🏼‍🤝‍👩🏽",
          "👩🏼‍🤝‍👩🏾",
          "👩🏼‍🤝‍👩🏿",
          "👩🏽‍🤝‍👩🏻",
          "👩🏽‍🤝‍👩🏼",
          "👭🏽",
          "👩🏽‍🤝‍👩🏾",
          "👩🏽‍🤝‍👩🏿",
          "👩🏾‍🤝‍👩🏻",
          "👩🏾‍🤝‍👩🏼",
          "👩🏾‍🤝‍👩🏽",
          "👭🏾",
          "👩🏾‍🤝‍👩🏿",
          "👩🏿‍🤝‍👩🏻",
          "👩🏿‍🤝‍👩🏼",
          "👩🏿‍🤝‍👩🏽",
          "👩🏿‍🤝‍👩🏾",
          "👭🏿",
          "👫",
          "👫🏻",
          "👩🏻‍🤝‍👨🏼",
          "👩🏻‍🤝‍👨🏽",
          "👩🏻‍🤝‍👨🏾",
          "👩🏻‍🤝‍👨🏿",
          "👩🏼‍🤝‍👨🏻",
          "👫🏼",
          "👩🏼‍🤝‍👨🏽",
          "👩🏼‍🤝‍👨🏾",
          "👩🏼‍🤝‍👨🏿",
          "👩🏽‍🤝‍👨🏻",
          "👩🏽‍🤝‍👨🏼",
          "👫🏽",
          "👩🏽‍🤝‍👨🏾",
          "👩🏽‍🤝‍👨🏿",
          "👩🏾‍🤝‍👨🏻",
          "👩🏾‍🤝‍👨🏼",
          "👩🏾‍🤝‍👨🏽",
          "👫🏾",
          "👩🏾‍🤝‍👨🏿",
          "👩🏿‍🤝‍👨🏻",
          "👩🏿‍🤝‍👨🏼",
          "👩🏿‍🤝‍👨🏽",
          "👩🏿‍🤝‍👨🏾",
          "👫🏿",
          "👬",
          "👬🏻",
          "👨🏻‍🤝‍👨🏼",
          "👨🏻‍🤝‍👨🏽",
          "👨🏻‍🤝‍👨🏾",
          "👨🏻‍🤝‍👨🏿",
          "👨🏼‍🤝‍👨🏻",
          "👬🏼",
          "👨🏼‍🤝‍👨🏽",
          "👨🏼‍🤝‍👨🏾",
          "👨🏼‍🤝‍👨🏿",
          "👨🏽‍🤝‍👨🏻",
          "👨🏽‍🤝‍👨🏼",
          "👬🏽",
          "👨🏽‍🤝‍👨🏾",
          "👨🏽‍🤝‍👨🏿",
          "👨🏾‍🤝‍👨🏻",
          "👨🏾‍🤝‍👨🏼",
          "👨🏾‍🤝‍👨🏽",
          "👬🏾",
          "👨🏾‍🤝‍👨🏿",
          "👨🏿‍🤝‍👨🏻",
          "👨🏿‍🤝‍👨🏼",
          "👨🏿‍🤝‍👨🏽",
          "👨🏿‍🤝‍👨🏾",
          "👬🏿",
          "💏",
          "💏🏻",
          "💏🏼",
          "💏🏽",
          "💏🏾",
          "💏🏿",
          "🧑🏻‍❤️‍💋‍🧑🏼",
          "🧑🏻‍❤‍💋‍🧑🏼",
          "🧑🏻‍❤️‍💋‍🧑🏽",
          "🧑🏻‍❤‍💋‍🧑🏽",
          "🧑🏻‍❤️‍💋‍🧑🏾",
          "🧑🏻‍❤‍💋‍🧑🏾",
          "🧑🏻‍❤️‍💋‍🧑🏿",
          "🧑🏻‍❤‍💋‍🧑🏿",
          "🧑🏼‍❤️‍💋‍🧑🏻",
          "🧑🏼‍❤‍💋‍🧑🏻",
          "🧑🏼‍❤️‍💋‍🧑🏽",
          "🧑🏼‍❤‍💋‍🧑🏽",
          "🧑🏼‍❤️‍💋‍🧑🏾",
          "🧑🏼‍❤‍💋‍🧑🏾",
          "🧑🏼‍❤️‍💋‍🧑🏿",
          "🧑🏼‍❤‍💋‍🧑🏿",
          "🧑🏽‍❤️‍💋‍🧑🏻",
          "🧑🏽‍❤‍💋‍🧑🏻",
          "🧑🏽‍❤️‍💋‍🧑🏼",
          "🧑🏽‍❤‍💋‍🧑🏼",
          "🧑🏽‍❤️‍💋‍🧑🏾",
          "🧑🏽‍❤‍💋‍🧑🏾",
          "🧑🏽‍❤️‍💋‍🧑🏿",
          "🧑🏽‍❤‍💋‍🧑🏿",
          "🧑🏾‍❤️‍💋‍🧑🏻",
          "🧑🏾‍❤‍💋‍🧑🏻",
          "🧑🏾‍❤️‍💋‍🧑🏼",
          "🧑🏾‍❤‍💋‍🧑🏼",
          "🧑🏾‍❤️‍💋‍🧑🏽",
          "🧑🏾‍❤‍💋‍🧑🏽",
          "🧑🏾‍❤️‍💋‍🧑🏿",
          "🧑🏾‍❤‍💋‍🧑🏿",
          "🧑🏿‍❤️‍💋‍🧑🏻",
          "🧑🏿‍❤‍💋‍🧑🏻",
          "🧑🏿‍❤️‍💋‍🧑🏼",
          "🧑🏿‍❤‍💋‍🧑🏼",
          "🧑🏿‍❤️‍💋‍🧑🏽",
          "🧑🏿‍❤‍💋‍🧑🏽",
          "🧑🏿‍❤️‍💋‍🧑🏾",
          "🧑🏿‍❤‍💋‍🧑🏾",
          "👩‍❤️‍💋‍👨",
          "👩‍❤‍💋‍👨",
          "👩🏻‍❤️‍💋‍👨🏻",
          "👩🏻‍❤‍💋‍👨🏻",
          "👩🏻‍❤️‍💋‍👨🏼",
          "👩🏻‍❤‍💋‍👨🏼",
          "👩🏻‍❤️‍💋‍👨🏽",
          "👩🏻‍❤‍💋‍👨🏽",
          "👩🏻‍❤️‍💋‍👨🏾",
          "👩🏻‍❤‍💋‍👨🏾",
          "👩🏻‍❤️‍💋‍👨🏿",
          "👩🏻‍❤‍💋‍👨🏿",
          "👩🏼‍❤️‍💋‍👨🏻",
          "👩🏼‍❤‍💋‍👨🏻",
          "👩🏼‍❤️‍💋‍👨🏼",
          "👩🏼‍❤‍💋‍👨🏼",
          "👩🏼‍❤️‍💋‍👨🏽",
          "👩🏼‍❤‍💋‍👨🏽",
          "👩🏼‍❤️‍💋‍👨🏾",
          "👩🏼‍❤‍💋‍👨🏾",
          "👩🏼‍❤️‍💋‍👨🏿",
          "👩🏼‍❤‍💋‍👨🏿",
          "👩🏽‍❤️‍💋‍👨🏻",
          "👩🏽‍❤‍💋‍👨🏻",
          "👩🏽‍❤️‍💋‍👨🏼",
          "👩🏽‍❤‍💋‍👨🏼",
          "👩🏽‍❤️‍💋‍👨🏽",
          "👩🏽‍❤‍💋‍👨🏽",
          "👩🏽‍❤️‍💋‍👨🏾",
          "👩🏽‍❤‍💋‍👨🏾",
          "👩🏽‍❤️‍💋‍👨🏿",
          "👩🏽‍❤‍💋‍👨🏿",
          "👩🏾‍❤️‍💋‍👨🏻",
          "👩🏾‍❤‍💋‍👨🏻",
          "👩🏾‍❤️‍💋‍👨🏼",
          "👩🏾‍❤‍💋‍👨🏼",
          "👩🏾‍❤️‍💋‍👨🏽",
          "👩🏾‍❤‍💋‍👨🏽",
          "👩🏾‍❤️‍💋‍👨🏾",
          "👩🏾‍❤‍💋‍👨🏾",
          "👩🏾‍❤️‍💋‍👨🏿",
          "👩🏾‍❤‍💋‍👨🏿",
          "👩🏿‍❤️‍💋‍👨🏻",
          "👩🏿‍❤‍💋‍👨🏻",
          "👩🏿‍❤️‍💋‍👨🏼",
          "👩🏿‍❤‍💋‍👨🏼",
          "👩🏿‍❤️‍💋‍👨🏽",
          "👩🏿‍❤‍💋‍👨🏽",
          "👩🏿‍❤️‍💋‍👨🏾",
          "👩🏿‍❤‍💋‍👨🏾",
          "👩🏿‍❤️‍💋‍👨🏿",
          "👩🏿‍❤‍💋‍👨🏿",
          "👨‍❤️‍💋‍👨",
          "👨‍❤‍💋‍👨",
          "👨🏻‍❤️‍💋‍👨🏻",
          "👨🏻‍❤‍💋‍👨🏻",
          "👨🏻‍❤️‍💋‍👨🏼",
          "👨🏻‍❤‍💋‍👨🏼",
          "👨🏻‍❤️‍💋‍👨🏽",
          "👨🏻‍❤‍💋‍👨🏽",
          "👨🏻‍❤️‍💋‍👨🏾",
          "👨🏻‍❤‍💋‍👨🏾",
          "👨🏻‍❤️‍💋‍👨🏿",
          "👨🏻‍❤‍💋‍👨🏿",
          "👨🏼‍❤️‍💋‍👨🏻",
          "👨🏼‍❤‍💋‍👨🏻",
          "👨🏼‍❤️‍💋‍👨🏼",
          "👨🏼‍❤‍💋‍👨🏼",
          "👨🏼‍❤️‍💋‍👨🏽",
          "👨🏼‍❤‍💋‍👨🏽",
          "👨🏼‍❤️‍💋‍👨🏾",
          "👨🏼‍❤‍💋‍👨🏾",
          "👨🏼‍❤️‍💋‍👨🏿",
          "👨🏼‍❤‍💋‍👨🏿",
          "👨🏽‍❤️‍💋‍👨🏻",
          "👨🏽‍❤‍💋‍👨🏻",
          "👨🏽‍❤️‍💋‍👨🏼",
          "👨🏽‍❤‍💋‍👨🏼",
          "👨🏽‍❤️‍💋‍👨🏽",
          "👨🏽‍❤‍💋‍👨🏽",
          "👨🏽‍❤️‍💋‍👨🏾",
          "👨🏽‍❤‍💋‍👨🏾",
          "👨🏽‍❤️‍💋‍👨🏿",
          "👨🏽‍❤‍💋‍👨🏿",
          "👨🏾‍❤️‍💋‍👨🏻",
          "👨🏾‍❤‍💋‍👨🏻",
          "👨🏾‍❤️‍💋‍👨🏼",
          "👨🏾‍❤‍💋‍👨🏼",
          "👨🏾‍❤️‍💋‍👨🏽",
          "👨🏾‍❤‍💋‍👨🏽",
          "👨🏾‍❤️‍💋‍👨🏾",
          "👨🏾‍❤‍💋‍👨🏾",
          "👨🏾‍❤️‍💋‍👨🏿",
          "👨🏾‍❤‍💋‍👨🏿",
          "👨🏿‍❤️‍💋‍👨🏻",
          "👨🏿‍❤‍💋‍👨🏻",
          "👨🏿‍❤️‍💋‍👨🏼",
          "👨🏿‍❤‍💋‍👨🏼",
          "👨🏿‍❤️‍💋‍👨🏽",
          "👨🏿‍❤‍💋‍👨🏽",
          "👨🏿‍❤️‍💋‍👨🏾",
          "👨🏿‍❤‍💋‍👨🏾",
          "👨🏿‍❤️‍💋‍👨🏿",
          "👨🏿‍❤‍💋‍👨🏿",
          "👩‍❤️‍💋‍👩",
          "👩‍❤‍💋‍👩",
          "👩🏻‍❤️‍💋‍👩🏻",
          "👩🏻‍❤‍💋‍👩🏻",
          "👩🏻‍❤️‍💋‍👩🏼",
          "👩🏻‍❤‍💋‍👩🏼",
          "👩🏻‍❤️‍💋‍👩🏽",
          "👩🏻‍❤‍💋‍👩🏽",
          "👩🏻‍❤️‍💋‍👩🏾",
          "👩🏻‍❤‍💋‍👩🏾",
          "👩🏻‍❤️‍💋‍👩🏿",
          "👩🏻‍❤‍💋‍👩🏿",
          "👩🏼‍❤️‍💋‍👩🏻",
          "👩🏼‍❤‍💋‍👩🏻",
          "👩🏼‍❤️‍💋‍👩🏼",
          "👩🏼‍❤‍💋‍👩🏼",
          "👩🏼‍❤️‍💋‍👩🏽",
          "👩🏼‍❤‍💋‍👩🏽",
          "👩🏼‍❤️‍💋‍👩🏾",
          "👩🏼‍❤‍💋‍👩🏾",
          "👩🏼‍❤️‍💋‍👩🏿",
          "👩🏼‍❤‍💋‍👩🏿",
          "👩🏽‍❤️‍💋‍👩🏻",
          "👩🏽‍❤‍💋‍👩🏻",
          "👩🏽‍❤️‍💋‍👩🏼",
          "👩🏽‍❤‍💋‍👩🏼",
          "👩🏽‍❤️‍💋‍👩🏽",
          "👩🏽‍❤‍💋‍👩🏽",
          "👩🏽‍❤️‍💋‍👩🏾",
          "👩🏽‍❤‍💋‍👩🏾",
          "👩🏽‍❤️‍💋‍👩🏿",
          "👩🏽‍❤‍💋‍👩🏿",
          "👩🏾‍❤️‍💋‍👩🏻",
          "👩🏾‍❤‍💋‍👩🏻",
          "👩🏾‍❤️‍💋‍👩🏼",
          "👩🏾‍❤‍💋‍👩🏼",
          "👩🏾‍❤️‍💋‍👩🏽",
          "👩🏾‍❤‍💋‍👩🏽",
          "👩🏾‍❤️‍💋‍👩🏾",
          "👩🏾‍❤‍💋‍👩🏾",
          "👩🏾‍❤️‍💋‍👩🏿",
          "👩🏾‍❤‍💋‍👩🏿",
          "👩🏿‍❤️‍💋‍👩🏻",
          "👩🏿‍❤‍💋‍👩🏻",
          "👩🏿‍❤️‍💋‍👩🏼",
          "👩🏿‍❤‍💋‍👩🏼",
          "👩🏿‍❤️‍💋‍👩🏽",
          "👩🏿‍❤‍💋‍👩🏽",
          "👩🏿‍❤️‍💋‍👩🏾",
          "👩🏿‍❤‍💋‍👩🏾",
          "👩🏿‍❤️‍💋‍👩🏿",
          "👩🏿‍❤‍💋‍👩🏿",
          "💑",
          "💑🏻",
          "💑🏼",
          "💑🏽",
          "💑🏾",
          "💑🏿",
          "🧑🏻‍❤️‍🧑🏼",
          "🧑🏻‍❤‍🧑🏼",
          "🧑🏻‍❤️‍🧑🏽",
          "🧑🏻‍❤‍🧑🏽",
          "🧑🏻‍❤️‍🧑🏾",
          "🧑🏻‍❤‍🧑🏾",
          "🧑🏻‍❤️‍🧑🏿",
          "🧑🏻‍❤‍🧑🏿",
          "🧑🏼‍❤️‍🧑🏻",
          "🧑🏼‍❤‍🧑🏻",
          "🧑🏼‍❤️‍🧑🏽",
          "🧑🏼‍❤‍🧑🏽",
          "🧑🏼‍❤️‍🧑🏾",
          "🧑🏼‍❤‍🧑🏾",
          "🧑🏼‍❤️‍🧑🏿",
          "🧑🏼‍❤‍🧑🏿",
          "🧑🏽‍❤️‍🧑🏻",
          "🧑🏽‍❤‍🧑🏻",
          "🧑🏽‍❤️‍🧑🏼",
          "🧑🏽‍❤‍🧑🏼",
          "🧑🏽‍❤️‍🧑🏾",
          "🧑🏽‍❤‍🧑🏾",
          "🧑🏽‍❤️‍🧑🏿",
          "🧑🏽‍❤‍🧑🏿",
          "🧑🏾‍❤️‍🧑🏻",
          "🧑🏾‍❤‍🧑🏻",
          "🧑🏾‍❤️‍🧑🏼",
          "🧑🏾‍❤‍🧑🏼",
          "🧑🏾‍❤️‍🧑🏽",
          "🧑🏾‍❤‍🧑🏽",
          "🧑🏾‍❤️‍🧑🏿",
          "🧑🏾‍❤‍🧑🏿",
          "🧑🏿‍❤️‍🧑🏻",
          "🧑🏿‍❤‍🧑🏻",
          "🧑🏿‍❤️‍🧑🏼",
          "🧑🏿‍❤‍🧑🏼",
          "🧑🏿‍❤️‍🧑🏽",
          "🧑🏿‍❤‍🧑🏽",
          "🧑🏿‍❤️‍🧑🏾",
          "🧑🏿‍❤‍🧑🏾",
          "👩‍❤️‍👨",
          "👩‍❤‍👨",
          "👩🏻‍❤️‍👨🏻",
          "👩🏻‍❤‍👨🏻",
          "👩🏻‍❤️‍👨🏼",
          "👩🏻‍❤‍👨🏼",
          "👩🏻‍❤️‍👨🏽",
          "👩🏻‍❤‍👨🏽",
          "👩🏻‍❤️‍👨🏾",
          "👩🏻‍❤‍👨🏾",
          "👩🏻‍❤️‍👨🏿",
          "👩🏻‍❤‍👨🏿",
          "👩🏼‍❤️‍👨🏻",
          "👩🏼‍❤‍👨🏻",
          "👩🏼‍❤️‍👨🏼",
          "👩🏼‍❤‍👨🏼",
          "👩🏼‍❤️‍👨🏽",
          "👩🏼‍❤‍👨🏽",
          "👩🏼‍❤️‍👨🏾",
          "👩🏼‍❤‍👨🏾",
          "👩🏼‍❤️‍👨🏿",
          "👩🏼‍❤‍👨🏿",
          "👩🏽‍❤️‍👨🏻",
          "👩🏽‍❤‍👨🏻",
          "👩🏽‍❤️‍👨🏼",
          "👩🏽‍❤‍👨🏼",
          "👩🏽‍❤️‍👨🏽",
          "👩🏽‍❤‍👨🏽",
          "👩🏽‍❤️‍👨🏾",
          "👩🏽‍❤‍👨🏾",
          "👩🏽‍❤️‍👨🏿",
          "👩🏽‍❤‍👨🏿",
          "👩🏾‍❤️‍👨🏻",
          "👩🏾‍❤‍👨🏻",
          "👩🏾‍❤️‍👨🏼",
          "👩🏾‍❤‍👨🏼",
          "👩🏾‍❤️‍👨🏽",
          "👩🏾‍❤‍👨🏽",
          "👩🏾‍❤️‍👨🏾",
          "👩🏾‍❤‍👨🏾",
          "👩🏾‍❤️‍👨🏿",
          "👩🏾‍❤‍👨🏿",
          "👩🏿‍❤️‍👨🏻",
          "👩🏿‍❤‍👨🏻",
          "👩🏿‍❤️‍👨🏼",
          "👩🏿‍❤‍👨🏼",
          "👩🏿‍❤️‍👨🏽",
          "👩🏿‍❤‍👨🏽",
          "👩🏿‍❤️‍👨🏾",
          "👩🏿‍❤‍👨🏾",
          "👩🏿‍❤️‍👨🏿",
          "👩🏿‍❤‍👨🏿",
          "👨‍❤️‍👨",
          "👨‍❤‍👨",
          "👨🏻‍❤️‍👨🏻",
          "👨🏻‍❤‍👨🏻",
          "👨🏻‍❤️‍👨🏼",
          "👨🏻‍❤‍👨🏼",
          "👨🏻‍❤️‍👨🏽",
          "👨🏻‍❤‍👨🏽",
          "👨🏻‍❤️‍👨🏾",
          "👨🏻‍❤‍👨🏾",
          "👨🏻‍❤️‍👨🏿",
          "👨🏻‍❤‍👨🏿",
          "👨🏼‍❤️‍👨🏻",
          "👨🏼‍❤‍👨🏻",
          "👨🏼‍❤️‍👨🏼",
          "👨🏼‍❤‍👨🏼",
          "👨🏼‍❤️‍👨🏽",
          "👨🏼‍❤‍👨🏽",
          "👨🏼‍❤️‍👨🏾",
          "👨🏼‍❤‍👨🏾",
          "👨🏼‍❤️‍👨🏿",
          "👨🏼‍❤‍👨🏿",
          "👨🏽‍❤️‍👨🏻",
          "👨🏽‍❤‍👨🏻",
          "👨🏽‍❤️‍👨🏼",
          "👨🏽‍❤‍👨🏼",
          "👨🏽‍❤️‍👨🏽",
          "👨🏽‍❤‍👨🏽",
          "👨🏽‍❤️‍👨🏾",
          "👨🏽‍❤‍👨🏾",
          "👨🏽‍❤️‍👨🏿",
          "👨🏽‍❤‍👨🏿",
          "👨🏾‍❤️‍👨🏻",
          "👨🏾‍❤‍👨🏻",
          "👨🏾‍❤️‍👨🏼",
          "👨🏾‍❤‍👨🏼",
          "👨🏾‍❤️‍👨🏽",
          "👨🏾‍❤‍👨🏽",
          "👨🏾‍❤️‍👨🏾",
          "👨🏾‍❤‍👨🏾",
          "👨🏾‍❤️‍👨🏿",
          "👨🏾‍❤‍👨🏿",
          "👨🏿‍❤️‍👨🏻",
          "👨🏿‍❤‍👨🏻",
          "👨🏿‍❤️‍👨🏼",
          "👨🏿‍❤‍👨🏼",
          "👨🏿‍❤️‍👨🏽",
          "👨🏿‍❤‍👨🏽",
          "👨🏿‍❤️‍👨🏾",
          "👨🏿‍❤‍👨🏾",
          "👨🏿‍❤️‍👨🏿",
          "👨🏿‍❤‍👨🏿",
          "👩‍❤️‍👩",
          "👩‍❤‍👩",
          "👩🏻‍❤️‍👩🏻",
          "👩🏻‍❤‍👩🏻",
          "👩🏻‍❤️‍👩🏼",
          "👩🏻‍❤‍👩🏼",
          "👩🏻‍❤️‍👩🏽",
          "👩🏻‍❤‍👩🏽",
          "👩🏻‍❤️‍👩🏾",
          "👩🏻‍❤‍👩🏾",
          "👩🏻‍❤️‍👩🏿",
          "👩🏻‍❤‍👩🏿",
          "👩🏼‍❤️‍👩🏻",
          "👩🏼‍❤‍👩🏻",
          "👩🏼‍❤️‍👩🏼",
          "👩🏼‍❤‍👩🏼",
          "👩🏼‍❤️‍👩🏽",
          "👩🏼‍❤‍👩🏽",
          "👩🏼‍❤️‍👩🏾",
          "👩🏼‍❤‍👩🏾",
          "👩🏼‍❤️‍👩🏿",
          "👩🏼‍❤‍👩🏿",
          "👩🏽‍❤️‍👩🏻",
          "👩🏽‍❤‍👩🏻",
          "👩🏽‍❤️‍👩🏼",
          "👩🏽‍❤‍👩🏼",
          "👩🏽‍❤️‍👩🏽",
          "👩🏽‍❤‍👩🏽",
          "👩🏽‍❤️‍👩🏾",
          "👩🏽‍❤‍👩🏾",
          "👩🏽‍❤️‍👩🏿",
          "👩🏽‍❤‍👩🏿",
          "👩🏾‍❤️‍👩🏻",
          "👩🏾‍❤‍👩🏻",
          "👩🏾‍❤️‍👩🏼",
          "👩🏾‍❤‍👩🏼",
          "👩🏾‍❤️‍👩🏽",
          "👩🏾‍❤‍👩🏽",
          "👩🏾‍❤️‍👩🏾",
          "👩🏾‍❤‍👩🏾",
          "👩🏾‍❤️‍👩🏿",
          "👩🏾‍❤‍👩🏿",
          "👩🏿‍❤️‍👩🏻",
          "👩🏿‍❤‍👩🏻",
          "👩🏿‍❤️‍👩🏼",
          "👩🏿‍❤‍👩🏼",
          "👩🏿‍❤️‍👩🏽",
          "👩🏿‍❤‍👩🏽",
          "👩🏿‍❤️‍👩🏾",
          "👩🏿‍❤‍👩🏾",
          "👩🏿‍❤️‍👩🏿",
          "👩🏿‍❤‍👩🏿",
          "👨‍👩‍👦",
          "👨‍👩‍👧",
          "👨‍👩‍👧‍👦",
          "👨‍👩‍👦‍👦",
          "👨‍👩‍👧‍👧",
          "👨‍👨‍👦",
          "👨‍👨‍👧",
          "👨‍👨‍👧‍👦",
          "👨‍👨‍👦‍👦",
          "👨‍👨‍👧‍👧",
          "👩‍👩‍👦",
          "👩‍👩‍👧",
          "👩‍👩‍👧‍👦",
          "👩‍👩‍👦‍👦",
          "👩‍👩‍👧‍👧",
          "👨‍👦",
          "👨‍👦‍👦",
          "👨‍👧",
          "👨‍👧‍👦",
          "👨‍👧‍👧",
          "👩‍👦",
          "👩‍👦‍👦",
          "👩‍👧",
          "👩‍👧‍👦",
          "👩‍👧‍👧"
      ],
      "person-symbol": [
          "🗣️",
          "🗣",
          "👤",
          "👥",
          "🫂",
          "👪",
          "🧑‍🧑‍🧒",
          "🧑‍🧑‍🧒‍🧒",
          "🧑‍🧒",
          "🧑‍🧒‍🧒",
          "👣",
          "🫆"
      ]
  },
  "Component": {
      "skin-tone": [
          "🏻",
          "🏼",
          "🏽",
          "🏾",
          "🏿"
      ],
      "hair-style": [
          "🦰",
          "🦱",
          "🦳",
          "🦲"
      ]
  },
  "Animals & Nature": {
      "animal-mammal": [
          "🐵",
          "🐒",
          "🦍",
          "🦧",
          "🐶",
          "🐕",
          "🦮",
          "🐕‍🦺",
          "🐩",
          "🐺",
          "🦊",
          "🦝",
          "🐱",
          "🐈",
          "🐈‍⬛",
          "🦁",
          "🐯",
          "🐅",
          "🐆",
          "🐴",
          "🫎",
          "🫏",
          "🐎",
          "🦄",
          "🦓",
          "🦌",
          "🦬",
          "🐮",
          "🐂",
          "🐃",
          "🐄",
          "🐷",
          "🐖",
          "🐗",
          "🐽",
          "🐏",
          "🐑",
          "🐐",
          "🐪",
          "🐫",
          "🦙",
          "🦒",
          "🐘",
          "🦣",
          "🦏",
          "🦛",
          "🐭",
          "🐁",
          "🐀",
          "🐹",
          "🐰",
          "🐇",
          "🐿️",
          "🐿",
          "🦫",
          "🦔",
          "🦇",
          "🐻",
          "🐻‍❄️",
          "🐻‍❄",
          "🐨",
          "🐼",
          "🦥",
          "🦦",
          "🦨",
          "🦘",
          "🦡",
          "🐾"
      ],
      "animal-bird": [
          "🦃",
          "🐔",
          "🐓",
          "🐣",
          "🐤",
          "🐥",
          "🐦",
          "🐧",
          "🕊️",
          "🕊",
          "🦅",
          "🦆",
          "🦢",
          "🦉",
          "🦤",
          "🪶",
          "🦩",
          "🦚",
          "🦜",
          "🪽",
          "🐦‍⬛",
          "🪿",
          "🐦‍🔥"
      ],
      "animal-amphibian": [
          "🐸"
      ],
      "animal-reptile": [
          "🐊",
          "🐢",
          "🦎",
          "🐍",
          "🐲",
          "🐉",
          "🦕",
          "🦖"
      ],
      "animal-marine": [
          "🐳",
          "🐋",
          "🐬",
          "🦭",
          "🐟",
          "🐠",
          "🐡",
          "🦈",
          "🐙",
          "🐚",
          "🪸",
          "🪼",
          "🦀",
          "🦞",
          "🦐",
          "🦑",
          "🦪"
      ],
      "animal-bug": [
          "🐌",
          "🦋",
          "🐛",
          "🐜",
          "🐝",
          "🪲",
          "🐞",
          "🦗",
          "🪳",
          "🕷️",
          "🕷",
          "🕸️",
          "🕸",
          "🦂",
          "🦟",
          "🪰",
          "🪱",
          "🦠"
      ],
      "plant-flower": [
          "💐",
          "🌸",
          "💮",
          "🪷",
          "🏵️",
          "🏵",
          "🌹",
          "🥀",
          "🌺",
          "🌻",
          "🌼",
          "🌷",
          "🪻"
      ],
      "plant-other": [
          "🌱",
          "🪴",
          "🌲",
          "🌳",
          "🌴",
          "🌵",
          "🌾",
          "🌿",
          "☘️",
          "☘",
          "🍀",
          "🍁",
          "🍂",
          "🍃",
          "🪹",
          "🪺",
          "🍄",
          "🪾"
      ]
  },
  "Food & Drink": {
      "food-fruit": [
          "🍇",
          "🍈",
          "🍉",
          "🍊",
          "🍋",
          "🍋‍🟩",
          "🍌",
          "🍍",
          "🥭",
          "🍎",
          "🍏",
          "🍐",
          "🍑",
          "🍒",
          "🍓",
          "🫐",
          "🥝",
          "🍅",
          "🫒",
          "🥥"
      ],
      "food-vegetable": [
          "🥑",
          "🍆",
          "🥔",
          "🥕",
          "🌽",
          "🌶️",
          "🌶",
          "🫑",
          "🥒",
          "🥬",
          "🥦",
          "🧄",
          "🧅",
          "🥜",
          "🫘",
          "🌰",
          "🫚",
          "🫛",
          "🍄‍🟫",
          "🫜"
      ],
      "food-prepared": [
          "🍞",
          "🥐",
          "🥖",
          "🫓",
          "🥨",
          "🥯",
          "🥞",
          "🧇",
          "🧀",
          "🍖",
          "🍗",
          "🥩",
          "🥓",
          "🍔",
          "🍟",
          "🍕",
          "🌭",
          "🥪",
          "🌮",
          "🌯",
          "🫔",
          "🥙",
          "🧆",
          "🥚",
          "🍳",
          "🥘",
          "🍲",
          "🫕",
          "🥣",
          "🥗",
          "🍿",
          "🧈",
          "🧂",
          "🥫"
      ],
      "food-asian": [
          "🍱",
          "🍘",
          "🍙",
          "🍚",
          "🍛",
          "🍜",
          "🍝",
          "🍠",
          "🍢",
          "🍣",
          "🍤",
          "🍥",
          "🥮",
          "🍡",
          "🥟",
          "🥠",
          "🥡"
      ],
      "food-sweet": [
          "🍦",
          "🍧",
          "🍨",
          "🍩",
          "🍪",
          "🎂",
          "🍰",
          "🧁",
          "🥧",
          "🍫",
          "🍬",
          "🍭",
          "🍮",
          "🍯"
      ],
      "drink": [
          "🍼",
          "🥛",
          "☕",
          "🫖",
          "🍵",
          "🍶",
          "🍾",
          "🍷",
          "🍸",
          "🍹",
          "🍺",
          "🍻",
          "🥂",
          "🥃",
          "🫗",
          "🥤",
          "🧋",
          "🧃",
          "🧉",
          "🧊"
      ],
      "dishware": [
          "🥢",
          "🍽️",
          "🍽",
          "🍴",
          "🥄",
          "🔪",
          "🫙",
          "🏺"
      ]
  },
  "Travel & Places": {
      "place-map": [
          "🌍",
          "🌎",
          "🌏",
          "🌐",
          "🗺️",
          "🗺",
          "🗾",
          "🧭"
      ],
      "place-geographic": [
          "🏔️",
          "🏔",
          "⛰️",
          "⛰",
          "🌋",
          "🗻",
          "🏕️",
          "🏕",
          "🏖️",
          "🏖",
          "🏜️",
          "🏜",
          "🏝️",
          "🏝",
          "🏞️",
          "🏞"
      ],
      "place-building": [
          "🏟️",
          "🏟",
          "🏛️",
          "🏛",
          "🏗️",
          "🏗",
          "🧱",
          "🪨",
          "🪵",
          "🛖",
          "🏘️",
          "🏘",
          "🏚️",
          "🏚",
          "🏠",
          "🏡",
          "🏢",
          "🏣",
          "🏤",
          "🏥",
          "🏦",
          "🏨",
          "🏩",
          "🏪",
          "🏫",
          "🏬",
          "🏭",
          "🏯",
          "🏰",
          "💒",
          "🗼",
          "🗽"
      ],
      "place-religious": [
          "⛪",
          "🕌",
          "🛕",
          "🕍",
          "⛩️",
          "⛩",
          "🕋"
      ],
      "place-other": [
          "⛲",
          "⛺",
          "🌁",
          "🌃",
          "🏙️",
          "🏙",
          "🌄",
          "🌅",
          "🌆",
          "🌇",
          "🌉",
          "♨️",
          "♨",
          "🎠",
          "🛝",
          "🎡",
          "🎢",
          "💈",
          "🎪"
      ],
      "transport-ground": [
          "🚂",
          "🚃",
          "🚄",
          "🚅",
          "🚆",
          "🚇",
          "🚈",
          "🚉",
          "🚊",
          "🚝",
          "🚞",
          "🚋",
          "🚌",
          "🚍",
          "🚎",
          "🚐",
          "🚑",
          "🚒",
          "🚓",
          "🚔",
          "🚕",
          "🚖",
          "🚗",
          "🚘",
          "🚙",
          "🛻",
          "🚚",
          "🚛",
          "🚜",
          "🏎️",
          "🏎",
          "🏍️",
          "🏍",
          "🛵",
          "🦽",
          "🦼",
          "🛺",
          "🚲",
          "🛴",
          "🛹",
          "🛼",
          "🚏",
          "🛣️",
          "🛣",
          "🛤️",
          "🛤",
          "🛢️",
          "🛢",
          "⛽",
          "🛞",
          "🚨",
          "🚥",
          "🚦",
          "🛑",
          "🚧"
      ],
      "transport-water": [
          "⚓",
          "🛟",
          "⛵",
          "🛶",
          "🚤",
          "🛳️",
          "🛳",
          "⛴️",
          "⛴",
          "🛥️",
          "🛥",
          "🚢"
      ],
      "transport-air": [
          "✈️",
          "✈",
          "🛩️",
          "🛩",
          "🛫",
          "🛬",
          "🪂",
          "💺",
          "🚁",
          "🚟",
          "🚠",
          "🚡",
          "🛰️",
          "🛰",
          "🚀",
          "🛸"
      ],
      "hotel": [
          "🛎️",
          "🛎",
          "🧳"
      ],
      "time": [
          "⌛",
          "⏳",
          "⌚",
          "⏰",
          "⏱️",
          "⏱",
          "⏲️",
          "⏲",
          "🕰️",
          "🕰",
          "🕛",
          "🕧",
          "🕐",
          "🕜",
          "🕑",
          "🕝",
          "🕒",
          "🕞",
          "🕓",
          "🕟",
          "🕔",
          "🕠",
          "🕕",
          "🕡",
          "🕖",
          "🕢",
          "🕗",
          "🕣",
          "🕘",
          "🕤",
          "🕙",
          "🕥",
          "🕚",
          "🕦"
      ],
      "sky & weather": [
          "🌑",
          "🌒",
          "🌓",
          "🌔",
          "🌕",
          "🌖",
          "🌗",
          "🌘",
          "🌙",
          "🌚",
          "🌛",
          "🌜",
          "🌡️",
          "🌡",
          "☀️",
          "☀",
          "🌝",
          "🌞",
          "🪐",
          "⭐",
          "🌟",
          "🌠",
          "🌌",
          "☁️",
          "☁",
          "⛅",
          "⛈️",
          "⛈",
          "🌤️",
          "🌤",
          "🌥️",
          "🌥",
          "🌦️",
          "🌦",
          "🌧️",
          "🌧",
          "🌨️",
          "🌨",
          "🌩️",
          "🌩",
          "🌪️",
          "🌪",
          "🌫️",
          "🌫",
          "🌬️",
          "🌬",
          "🌀",
          "🌈",
          "🌂",
          "☂️",
          "☂",
          "☔",
          "⛱️",
          "⛱",
          "⚡",
          "❄️",
          "❄",
          "☃️",
          "☃",
          "⛄",
          "☄️",
          "☄",
          "🔥",
          "💧",
          "🌊"
      ]
  },
  "Activities": {
      "event": [
          "🎃",
          "🎄",
          "🎆",
          "🎇",
          "🧨",
          "✨",
          "🎈",
          "🎉",
          "🎊",
          "🎋",
          "🎍",
          "🎎",
          "🎏",
          "🎐",
          "🎑",
          "🧧",
          "🎀",
          "🎁",
          "🎗️",
          "🎗",
          "🎟️",
          "🎟",
          "🎫"
      ],
      "award-medal": [
          "🎖️",
          "🎖",
          "🏆",
          "🏅",
          "🥇",
          "🥈",
          "🥉"
      ],
      "sport": [
          "⚽",
          "⚾",
          "🥎",
          "🏀",
          "🏐",
          "🏈",
          "🏉",
          "🎾",
          "🥏",
          "🎳",
          "🏏",
          "🏑",
          "🏒",
          "🥍",
          "🏓",
          "🏸",
          "🥊",
          "🥋",
          "🥅",
          "⛳",
          "⛸️",
          "⛸",
          "🎣",
          "🤿",
          "🎽",
          "🎿",
          "🛷",
          "🥌"
      ],
      "game": [
          "🎯",
          "🪀",
          "🪁",
          "🔫",
          "🎱",
          "🔮",
          "🪄",
          "🎮",
          "🕹️",
          "🕹",
          "🎰",
          "🎲",
          "🧩",
          "🧸",
          "🪅",
          "🪩",
          "🪆",
          "♠️",
          "♠",
          "♥️",
          "♥",
          "♦️",
          "♦",
          "♣️",
          "♣",
          "♟️",
          "♟",
          "🃏",
          "🀄",
          "🎴"
      ],
      "arts & crafts": [
          "🎭",
          "🖼️",
          "🖼",
          "🎨",
          "🧵",
          "🪡",
          "🧶",
          "🪢"
      ]
  },
  "Objects": {
      "clothing": [
          "👓",
          "🕶️",
          "🕶",
          "🥽",
          "🥼",
          "🦺",
          "👔",
          "👕",
          "👖",
          "🧣",
          "🧤",
          "🧥",
          "🧦",
          "👗",
          "👘",
          "🥻",
          "🩱",
          "🩲",
          "🩳",
          "👙",
          "👚",
          "🪭",
          "👛",
          "👜",
          "👝",
          "🛍️",
          "🛍",
          "🎒",
          "🩴",
          "👞",
          "👟",
          "🥾",
          "🥿",
          "👠",
          "👡",
          "🩰",
          "👢",
          "🪮",
          "👑",
          "👒",
          "🎩",
          "🎓",
          "🧢",
          "🪖",
          "⛑️",
          "⛑",
          "📿",
          "💄",
          "💍",
          "💎"
      ],
      "sound": [
          "🔇",
          "🔈",
          "🔉",
          "🔊",
          "📢",
          "📣",
          "📯",
          "🔔",
          "🔕"
      ],
      "music": [
          "🎼",
          "🎵",
          "🎶",
          "🎙️",
          "🎙",
          "🎚️",
          "🎚",
          "🎛️",
          "🎛",
          "🎤",
          "🎧",
          "📻"
      ],
      "musical-instrument": [
          "🎷",
          "🪗",
          "🎸",
          "🎹",
          "🎺",
          "🎻",
          "🪕",
          "🥁",
          "🪘",
          "🪇",
          "🪈",
          "🪉"
      ],
      "phone": [
          "📱",
          "📲",
          "☎️",
          "☎",
          "📞",
          "📟",
          "📠"
      ],
      "computer": [
          "🔋",
          "🪫",
          "🔌",
          "💻",
          "🖥️",
          "🖥",
          "🖨️",
          "🖨",
          "⌨️",
          "⌨",
          "🖱️",
          "🖱",
          "🖲️",
          "🖲",
          "💽",
          "💾",
          "💿",
          "📀",
          "🧮"
      ],
      "light & video": [
          "🎥",
          "🎞️",
          "🎞",
          "📽️",
          "📽",
          "🎬",
          "📺",
          "📷",
          "📸",
          "📹",
          "📼",
          "🔍",
          "🔎",
          "🕯️",
          "🕯",
          "💡",
          "🔦",
          "🏮",
          "🪔"
      ],
      "book-paper": [
          "📔",
          "📕",
          "📖",
          "📗",
          "📘",
          "📙",
          "📚",
          "📓",
          "📒",
          "📃",
          "📜",
          "📄",
          "📰",
          "🗞️",
          "🗞",
          "📑",
          "🔖",
          "🏷️",
          "🏷"
      ],
      "money": [
          "💰",
          "🪙",
          "💴",
          "💵",
          "💶",
          "💷",
          "💸",
          "💳",
          "🧾",
          "💹"
      ],
      "mail": [
          "✉️",
          "✉",
          "📧",
          "📨",
          "📩",
          "📤",
          "📥",
          "📦",
          "📫",
          "📪",
          "📬",
          "📭",
          "📮",
          "🗳️",
          "🗳"
      ],
      "writing": [
          "✏️",
          "✏",
          "✒️",
          "✒",
          "🖋️",
          "🖋",
          "🖊️",
          "🖊",
          "🖌️",
          "🖌",
          "🖍️",
          "🖍",
          "📝"
      ],
      "office": [
          "💼",
          "📁",
          "📂",
          "🗂️",
          "🗂",
          "📅",
          "📆",
          "🗒️",
          "🗒",
          "🗓️",
          "🗓",
          "📇",
          "📈",
          "📉",
          "📊",
          "📋",
          "📌",
          "📍",
          "📎",
          "🖇️",
          "🖇",
          "📏",
          "📐",
          "✂️",
          "✂",
          "🗃️",
          "🗃",
          "🗄️",
          "🗄",
          "🗑️",
          "🗑"
      ],
      "lock": [
          "🔒",
          "🔓",
          "🔏",
          "🔐",
          "🔑",
          "🗝️",
          "🗝"
      ],
      "tool": [
          "🔨",
          "🪓",
          "⛏️",
          "⛏",
          "⚒️",
          "⚒",
          "🛠️",
          "🛠",
          "🗡️",
          "🗡",
          "⚔️",
          "⚔",
          "💣",
          "🪃",
          "🏹",
          "🛡️",
          "🛡",
          "🪚",
          "🔧",
          "🪛",
          "🔩",
          "⚙️",
          "⚙",
          "🗜️",
          "🗜",
          "⚖️",
          "⚖",
          "🦯",
          "🔗",
          "⛓️‍💥",
          "⛓‍💥",
          "⛓️",
          "⛓",
          "🪝",
          "🧰",
          "🧲",
          "🪜",
          "🪏"
      ],
      "science": [
          "⚗️",
          "⚗",
          "🧪",
          "🧫",
          "🧬",
          "🔬",
          "🔭",
          "📡"
      ],
      "medical": [
          "💉",
          "🩸",
          "💊",
          "🩹",
          "🩼",
          "🩺",
          "🩻"
      ],
      "household": [
          "🚪",
          "🛗",
          "🪞",
          "🪟",
          "🛏️",
          "🛏",
          "🛋️",
          "🛋",
          "🪑",
          "🚽",
          "🪠",
          "🚿",
          "🛁",
          "🪤",
          "🪒",
          "🧴",
          "🧷",
          "🧹",
          "🧺",
          "🧻",
          "🪣",
          "🧼",
          "🫧",
          "🪥",
          "🧽",
          "🧯",
          "🛒"
      ],
      "other-object": [
          "🚬",
          "⚰️",
          "⚰",
          "🪦",
          "⚱️",
          "⚱",
          "🧿",
          "🪬",
          "🗿",
          "🪧",
          "🪪"
      ]
  },
  "Symbols": {
      "transport-sign": [
          "🏧",
          "🚮",
          "🚰",
          "♿",
          "🚹",
          "🚺",
          "🚻",
          "🚼",
          "🚾",
          "🛂",
          "🛃",
          "🛄",
          "🛅"
      ],
      "warning": [
          "⚠️",
          "⚠",
          "🚸",
          "⛔",
          "🚫",
          "🚳",
          "🚭",
          "🚯",
          "🚱",
          "🚷",
          "📵",
          "🔞",
          "☢️",
          "☢",
          "☣️",
          "☣"
      ],
      "arrow": [
          "⬆️",
          "⬆",
          "↗️",
          "↗",
          "➡️",
          "➡",
          "↘️",
          "↘",
          "⬇️",
          "⬇",
          "↙️",
          "↙",
          "⬅️",
          "⬅",
          "↖️",
          "↖",
          "↕️",
          "↕",
          "↔️",
          "↔",
          "↩️",
          "↩",
          "↪️",
          "↪",
          "⤴️",
          "⤴",
          "⤵️",
          "⤵",
          "🔃",
          "🔄",
          "🔙",
          "🔚",
          "🔛",
          "🔜",
          "🔝"
      ],
      "religion": [
          "🛐",
          "⚛️",
          "⚛",
          "🕉️",
          "🕉",
          "✡️",
          "✡",
          "☸️",
          "☸",
          "☯️",
          "☯",
          "✝️",
          "✝",
          "☦️",
          "☦",
          "☪️",
          "☪",
          "☮️",
          "☮",
          "🕎",
          "🔯",
          "🪯"
      ],
      "zodiac": [
          "♈",
          "♉",
          "♊",
          "♋",
          "♌",
          "♍",
          "♎",
          "♏",
          "♐",
          "♑",
          "♒",
          "♓",
          "⛎"
      ],
      "av-symbol": [
          "🔀",
          "🔁",
          "🔂",
          "▶️",
          "▶",
          "⏩",
          "⏭️",
          "⏭",
          "⏯️",
          "⏯",
          "◀️",
          "◀",
          "⏪",
          "⏮️",
          "⏮",
          "🔼",
          "⏫",
          "🔽",
          "⏬",
          "⏸️",
          "⏸",
          "⏹️",
          "⏹",
          "⏺️",
          "⏺",
          "⏏️",
          "⏏",
          "🎦",
          "🔅",
          "🔆",
          "📶",
          "🛜",
          "📳",
          "📴"
      ],
      "gender": [
          "♀️",
          "♀",
          "♂️",
          "♂",
          "⚧️",
          "⚧"
      ],
      "math": [
          "✖️",
          "✖",
          "➕",
          "➖",
          "➗",
          "🟰",
          "♾️",
          "♾"
      ],
      "punctuation": [
          "‼️",
          "‼",
          "⁉️",
          "⁉",
          "❓",
          "❔",
          "❕",
          "❗",
          "〰️",
          "〰"
      ],
      "currency": [
          "💱",
          "💲"
      ],
      "other-symbol": [
          "⚕️",
          "⚕",
          "♻️",
          "♻",
          "⚜️",
          "⚜",
          "🔱",
          "📛",
          "🔰",
          "⭕",
          "✅",
          "☑️",
          "☑",
          "✔️",
          "✔",
          "❌",
          "❎",
          "➰",
          "➿",
          "〽️",
          "〽",
          "✳️",
          "✳",
          "✴️",
          "✴",
          "❇️",
          "❇",
          "©️",
          "©",
          "®️",
          "®",
          "™️",
          "™",
          "🫟"
      ],
      "keycap": [
          "#️⃣",
          "#⃣",
          "*️⃣",
          "*⃣",
          "0️⃣",
          "0⃣",
          "1️⃣",
          "1⃣",
          "2️⃣",
          "2⃣",
          "3️⃣",
          "3⃣",
          "4️⃣",
          "4⃣",
          "5️⃣",
          "5⃣",
          "6️⃣",
          "6⃣",
          "7️⃣",
          "7⃣",
          "8️⃣",
          "8⃣",
          "9️⃣",
          "9⃣",
          "🔟"
      ],
      "alphanum": [
          "🔠",
          "🔡",
          "🔢",
          "🔣",
          "🔤",
          "🅰️",
          "🅰",
          "🆎",
          "🅱️",
          "🅱",
          "🆑",
          "🆒",
          "🆓",
          "ℹ️",
          "ℹ",
          "🆔",
          "Ⓜ️",
          "Ⓜ",
          "🆕",
          "🆖",
          "🅾️",
          "🅾",
          "🆗",
          "🅿️",
          "🅿",
          "🆘",
          "🆙",
          "🆚",
          "🈁",
          "🈂️",
          "🈂",
          "🈷️",
          "🈷",
          "🈶",
          "🈯",
          "🉐",
          "🈹",
          "🈚",
          "🈲",
          "🉑",
          "🈸",
          "🈴",
          "🈳",
          "㊗️",
          "㊗",
          "㊙️",
          "㊙",
          "🈺",
          "🈵"
      ],
      "geometric": [
          "🔴",
          "🟠",
          "🟡",
          "🟢",
          "🔵",
          "🟣",
          "🟤",
          "⚫",
          "⚪",
          "🟥",
          "🟧",
          "🟨",
          "🟩",
          "🟦",
          "🟪",
          "🟫",
          "⬛",
          "⬜",
          "◼️",
          "◼",
          "◻️",
          "◻",
          "◾",
          "◽",
          "▪️",
          "▪",
          "▫️",
          "▫",
          "🔶",
          "🔷",
          "🔸",
          "🔹",
          "🔺",
          "🔻",
          "💠",
          "🔘",
          "🔳",
          "🔲"
      ]
  },
  "Flags": {
      "flag": [
          "🏁",
          "🚩",
          "🎌",
          "🏴",
          "🏳️",
          "🏳",
          "🏳️‍🌈",
          "🏳‍🌈",
          "🏳️‍⚧️",
          "🏳‍⚧️",
          "🏳️‍⚧",
          "🏳‍⚧",
          "🏴‍☠️",
          "🏴‍☠"
      ],
      "country-flag": [
          "🇦🇨",
          "🇦🇩",
          "🇦🇪",
          "🇦🇫",
          "🇦🇬",
          "🇦🇮",
          "🇦🇱",
          "🇦🇲",
          "🇦🇴",
          "🇦🇶",
          "🇦🇷",
          "🇦🇸",
          "🇦🇹",
          "🇦🇺",
          "🇦🇼",
          "🇦🇽",
          "🇦🇿",
          "🇧🇦",
          "🇧🇧",
          "🇧🇩",
          "🇧🇪",
          "🇧🇫",
          "🇧🇬",
          "🇧🇭",
          "🇧🇮",
          "🇧🇯",
          "🇧🇱",
          "🇧🇲",
          "🇧🇳",
          "🇧🇴",
          "🇧🇶",
          "🇧🇷",
          "🇧🇸",
          "🇧🇹",
          "🇧🇻",
          "🇧🇼",
          "🇧🇾",
          "🇧🇿",
          "🇨🇦",
          "🇨🇨",
          "🇨🇩",
          "🇨🇫",
          "🇨🇬",
          "🇨🇭",
          "🇨🇮",
          "🇨🇰",
          "🇨🇱",
          "🇨🇲",
          "🇨🇳",
          "🇨🇴",
          "🇨🇵",
          "🇨🇶",
          "🇨🇷",
          "🇨🇺",
          "🇨🇻",
          "🇨🇼",
          "🇨🇽",
          "🇨🇾",
          "🇨🇿",
          "🇩🇪",
          "🇩🇬",
          "🇩🇯",
          "🇩🇰",
          "🇩🇲",
          "🇩🇴",
          "🇩🇿",
          "🇪🇦",
          "🇪🇨",
          "🇪🇪",
          "🇪🇬",
          "🇪🇭",
          "🇪🇷",
          "🇪🇸",
          "🇪🇹",
          "🇪🇺",
          "🇫🇮",
          "🇫🇯",
          "🇫🇰",
          "🇫🇲",
          "🇫🇴",
          "🇫🇷",
          "🇬🇦",
          "🇬🇧",
          "🇬🇩",
          "🇬🇪",
          "🇬🇫",
          "🇬🇬",
          "🇬🇭",
          "🇬🇮",
          "🇬🇱",
          "🇬🇲",
          "🇬🇳",
          "🇬🇵",
          "🇬🇶",
          "🇬🇷",
          "🇬🇸",
          "🇬🇹",
          "🇬🇺",
          "🇬🇼",
          "🇬🇾",
          "🇭🇰",
          "🇭🇲",
          "🇭🇳",
          "🇭🇷",
          "🇭🇹",
          "🇭🇺",
          "🇮🇨",
          "🇮🇩",
          "🇮🇪",
          "🇮🇱",
          "🇮🇲",
          "🇮🇳",
          "🇮🇴",
          "🇮🇶",
          "🇮🇷",
          "🇮🇸",
          "🇮🇹",
          "🇯🇪",
          "🇯🇲",
          "🇯🇴",
          "🇯🇵",
          "🇰🇪",
          "🇰🇬",
          "🇰🇭",
          "🇰🇮",
          "🇰🇲",
          "🇰🇳",
          "🇰🇵",
          "🇰🇷",
          "🇰🇼",
          "🇰🇾",
          "🇰🇿",
          "🇱🇦",
          "🇱🇧",
          "🇱🇨",
          "🇱🇮",
          "🇱🇰",
          "🇱🇷",
          "🇱🇸",
          "🇱🇹",
          "🇱🇺",
          "🇱🇻",
          "🇱🇾",
          "🇲🇦",
          "🇲🇨",
          "🇲🇩",
          "🇲🇪",
          "🇲🇫",
          "🇲🇬",
          "🇲🇭",
          "🇲🇰",
          "🇲🇱",
          "🇲🇲",
          "🇲🇳",
          "🇲🇴",
          "🇲🇵",
          "🇲🇶",
          "🇲🇷",
          "🇲🇸",
          "🇲🇹",
          "🇲🇺",
          "🇲🇻",
          "🇲🇼",
          "🇲🇽",
          "🇲🇾",
          "🇲🇿",
          "🇳🇦",
          "🇳🇨",
          "🇳🇪",
          "🇳🇫",
          "🇳🇬",
          "🇳🇮",
          "🇳🇱",
          "🇳🇴",
          "🇳🇵",
          "🇳🇷",
          "🇳🇺",
          "🇳🇿",
          "🇴🇲",
          "🇵🇦",
          "🇵🇪",
          "🇵🇫",
          "🇵🇬",
          "🇵🇭",
          "🇵🇰",
          "🇵🇱",
          "🇵🇲",
          "🇵🇳",
          "🇵🇷",
          "🇵🇸",
          "🇵🇹",
          "🇵🇼",
          "🇵🇾",
          "🇶🇦",
          "🇷🇪",
          "🇷🇴",
          "🇷🇸",
          "🇷🇺",
          "🇷🇼",
          "🇸🇦",
          "🇸🇧",
          "🇸🇨",
          "🇸🇩",
          "🇸🇪",
          "🇸🇬",
          "🇸🇭",
          "🇸🇮",
          "🇸🇯",
          "🇸🇰",
          "🇸🇱",
          "🇸🇲",
          "🇸🇳",
          "🇸🇴",
          "🇸🇷",
          "🇸🇸",
          "🇸🇹",
          "🇸🇻",
          "🇸🇽",
          "🇸🇾",
          "🇸🇿",
          "🇹🇦",
          "🇹🇨",
          "🇹🇩",
          "🇹🇫",
          "🇹🇬",
          "🇹🇭",
          "🇹🇯",
          "🇹🇰",
          "🇹🇱",
          "🇹🇲",
          "🇹🇳",
          "🇹🇴",
          "🇹🇷",
          "🇹🇹",
          "🇹🇻",
          "🇹🇼",
          "🇹🇿",
          "🇺🇦",
          "🇺🇬",
          "🇺🇲",
          "🇺🇳",
          "🇺🇸",
          "🇺🇾",
          "🇺🇿",
          "🇻🇦",
          "🇻🇨",
          "🇻🇪",
          "🇻🇬",
          "🇻🇮",
          "🇻🇳",
          "🇻🇺",
          "🇼🇫",
          "🇼🇸",
          "🇽🇰",
          "🇾🇪",
          "🇾🇹",
          "🇿🇦",
          "🇿🇲",
          "🇿🇼"
      ],
      "subdivision-flag": [
          "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
          "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
          "🏴󠁧󠁢󠁷󠁬󠁳󠁿"
      ]
  }
}

export default allEmojis;
