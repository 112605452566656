import invariant from 'tiny-invariant';

import createAudioPlayer from './audioPlayer';
import allEmojis from './emojis';
import * as sounds from './audio';
import musicFile from './music.m4a';


interface GameObjectBase {
  node: HTMLElement,
  x: number,
  y: number,
  dy: number,
  collected?: true,
  ended?: true,
}

type GameObjectSpecificProps = {
  type: 'emoji',
  health: number,
  maxHealth: number,
} | {
  type: 'poison',
} | {
  type: 'bomb',
}

type GameObject = GameObjectBase & GameObjectSpecificProps;

const audioPlayer = createAudioPlayer();

audioPlayer.loadAll(sounds);

const maxLevel = 5;
const categories = ['Smileys & Emotion', 'Animals & Nature', 'Food & Drink', 'Travel & Places', 'Activities', 'Flags'] as const;
const totalEmojis = 50;

const bombEmoji = '💣';
const poisonEmojis = ['💀', '☠️']
const specialEmojis = new Set([bombEmoji, ...poisonEmojis, '☠']); // excluded from normal emojis

const getPosionEmoji = () => poisonEmojis[Math.floor(Math.random() * poisonEmojis.length)]!;

function getLevelEmojis(num: number, level: number) {
  const category = categories[Math.floor(Math.random() * categories.length)]!;
  if (level > maxLevel) {
    return undefined;
  }
  const categoryEmojis = allEmojis[category];
  const selection = Object.values(categoryEmojis).flat().filter((emoji) => !specialEmojis.has(emoji));

  const emojis = [];

  for (let i = 0; i < num; i++) {
    const emoji = selection[Math.floor(Math.random() * selection.length)];
    invariant(emoji != null);
    emojis.push(emoji);
  }
  return emojis;
}

// https://stackoverflow.com/a/71393919/6519037
document.addEventListener('dblclick', (e) => e.preventDefault(), { passive: false })

function playSound(url: string, volume?: number) {
  audioPlayer.play(url, volume);
}

window.addEventListener('DOMContentLoaded', () => {
  window.onbeforeunload = () => true;

  const music = new Audio(musicFile)
  music.loop = true;
  music.play().catch(() => undefined);

  const arrowDown = document.querySelector('#arrow-down')!;
  const canvas = document.querySelector('#canvas')!;
  const hud = document.querySelector('#hud') as HTMLElement;
  const emojisNode = document.querySelector('#emojis')!;

  arrowDown.addEventListener('click', () => {
    canvas?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  });

  const calcXy = (dim: number) => `${dim}%`;

  const animateNodeIn = (node: HTMLElement) => node.animate([
    { opacity: 0 },
    { opacity: 1 },
  ], {
    duration: 1000,
    easing: 'ease-in',
  });

  let objects: GameObject[] = [];
  let wind = 0;
  let numBombs = 0;
  let collectedEmojis = 0;

  const animateSpecial = (node: HTMLElement, scale: number) => node.animate([
    {},
    { transform: `scale(${scale})`, filter: 'grayscale(1) brightness(150%) sepia(1) hue-rotate(320deg) saturate(6)', offset: 0.5 },
    {},
  ], {
    duration: 1000,
    easing: 'ease-in-out',
    iterations: Infinity
  });
  function maybeAddBomb() {
    if (Math.random() < 0.1) {
      const bombNode = createObject({ type: 'bomb' }, bombEmoji);
      animateSpecial(bombNode, 2);
    }
  }

  function addPoison() {
    const node = createObject({ type: 'poison' }, getPosionEmoji());
    animateSpecial(node, 0.7);
  }
  function maybeAddRandoms() {
    if (levelAt >= 2 && Math.random() < 0.05) {
      addPoison();
    }
  }

  function damageObject(object: GameObject, isBombed: boolean, damage: number) {
    const { node } = object;

    if ('health' in object) {
      object.health -= damage;
      if (object.health < 0) object.health = 0;
      const howDead = (object.maxHealth - object.health) / object.maxHealth;
      node.style.transition = 'filter 0.5s, transform 0.5s';
      node.style.transform = `scale(${howDead * 0.5 + 1})`;
      node.style.filter = `grayscale(1) sepia(1) hue-rotate(320deg) saturate(${6 * howDead})`;
      if (object.health > 0) return;
    }

    if (object.collected) return;
    object.collected = true;

    node.style.pointerEvents = 'none';

    node.addEventListener('transitionend', () => {
      if (object.ended) return;
      object.ended = true;
      removeObject(object);

      const remainingEmojis = objects.filter((o) => o.type === 'emoji').length;
      collectedEmojis = totalEmojis - remainingEmojis;
      // note: bombs/poisons will stay on
      if (collectedEmojis >= totalEmojis) {
        collectedEmojis = 0;
        levelAt++;
        initLevel();
        playSound('wow');
      }
    })
    node.style.transition = 'transform 0.8s, opacity 0.8s, filter 0.8s';
    node.style.transform = `scale(${Math.random() * 4 + 2}) skew(${-30 + Math.random() * 60}deg) rotate(${-30 + Math.random() * 60}deg)`;
    const filters = [`hue-rotate(${(Math.random() * 300) + 30}deg)`, 'brightness(3)'];
    node.style.filter = `${filters[Math.floor(Math.random() * filters.length)]}`;
    node.style.opacity = '0';

    if (object.type === 'emoji') {
      hud.querySelector('.star')?.animate([
        {},
        { transform: 'scale(2)', filter: 'brightness(3)', offset: 0.5 },
        {},
      ], {
        duration: 500,
        easing: 'ease-in-out',
      });

      if (!isBombed) maybeAddBomb();
      maybeAddRandoms();
    } else if (object.type === 'bomb') {
      playSound('hail');

      numBombs++;
    } else if (object.type === 'poison') {
      playSound('punch');
      window.alert('You died 💀');
      gameEnded = true;
    }
  }

  function handleTap(e: Event) {
    if (music.paused) music.play();

    const node = e.target;
    const hitObject = objects.find((o) => o.node === node);

    playSound('punch', hitObject ? 1 : 0.4);

    const clickNode = document.createElement('div');
    clickNode.innerText = hitObject ? '🌟' : '💥';
    clickNode.classList.add('point-star');
    const rect = canvas.getBoundingClientRect();
    const x = (e as PointerEvent).clientX - rect.left;
    const y = (e as PointerEvent).clientY - rect.top;

    const size = hitObject ? 20 : Math.random() * 2 + 3;
    clickNode.style.fontSize = `${size}vmin`;
    clickNode.style.marginLeft = `-${size/2}vmin`;
    clickNode.style.marginTop = `-${size/2}vmin`;

    clickNode.style.left = String(x);
    clickNode.style.top = String(y);

    clickNode.animate([
      { transform: 'scale(0)', opacity: 0.3 },
      { transform: `scale(${Math.random() * 0.5 + 1.3})`, opacity: 1, offset: 0.5 },
      { transform: `scale(1) rotate(${((Math.random() * 100) + 100) * (Math.random() > 0.5 ? 1 : -1)}deg)`, opacity: 0 }
    ], {
      duration: Math.random() * 200 + 200,
      easing: 'ease-out',
    }).finished.then(() => {
      canvas.removeChild(clickNode);
    });

    canvas.appendChild(clickNode);

    if (hitObject != null) {
      damageObject(hitObject, false, 100);
    }
  }

  emojisNode.addEventListener('pointerdown', handleTap);

  function triggerBomb() {
    if (numBombs === 0) return;

    playSound('explosion');

    const objectsInView = objects.filter((object) => {
      if (object.type === 'poison') return false;
      const rect = object.node.getBoundingClientRect();
      return rect.top < window.innerHeight && rect.bottom >= 0;
    });

    objectsInView.forEach((o) => damageObject(o, true, Infinity));

    numBombs--;
  }
  hud.querySelector('.bomb')!.addEventListener('pointerdown', triggerBomb);
  document.addEventListener('keydown', (e) => {
    if (e.key === ' ') {
      e.preventDefault();
      triggerBomb();
    }
    // cheat
    if (e.ctrlKey) {
      if (e.key === '1') {
        addPoison();
      }
      if (e.key === '2') {
        objects.filter((o) => o.type === 'emoji').forEach((o) => damageObject(o, true, Infinity));
      }
    }
  });

  const height = 1000;

  function removeObject(object: GameObject) {
    canvas.removeChild(object.node);
    objects = objects.filter((o) => o.node !== object.node);
  }

  function createObject(extraState: GameObjectSpecificProps, content: string) {
    const node = document.createElement('div');

    const object = {
      node,
      x: 5 + (Math.random() * 95),
      y: (Math.random() * height) / 10,
      dy: ((Math.random() * 7) + 3) / 100,
      ...extraState,
    };
    objects.push(object);

    node.innerText = content;
    node.classList.add('emoji');
    const fontSize = Math.random() * 7 + 10;
    node.style.fontSize = `${fontSize}vmin`;
    node.style.marginLeft = `${-fontSize / 2}vmin`;
    node.style.marginTop = `${-fontSize / 2}vmin`;
    node.style.left = calcXy(object.x);
    node.style.top = calcXy(object.y);

    animateNodeIn(node);
    canvas.appendChild(node);
    return node;
  }

  let levelAt = 0;
  let gameEnded = false;
  function initLevel() {
    const emojis = getLevelEmojis(totalEmojis, levelAt);
    if (emojis == null) {
      window.location.href = 'https://mifi.no/thanks/';
    } else {
      const health = levelAt === 0 ? 100 : 100 + Math.random() * 100 * levelAt;
      emojis.forEach((emoji) => createObject({
        type: 'emoji',
        health,
        maxHealth: health,
      }, emoji));
    }
  }

  initLevel();

  let gameVisible = false;

  const startTime = Date.now();

  function render() {
    if (Math.floor((Date.now() - startTime) / 1000) % 5 === 0) {
      wind = (Math.random() * 2 - 1) / 10;
    }

    objects.forEach((object) => {
      object.y += object.dy;
      object.x += wind;
      if (object.y > 100) {
        if (object.type === 'poison') {
          removeObject(object);
        } else {
          // re-spawn at top
          object.y = 0;
          animateNodeIn(object.node);
        }
      }
      if (object.x > 100) {
        object.x = 0;
        animateNodeIn(object.node);
      }
      if (object.x < 0) {
        object.x = 100;
        animateNodeIn(object.node);
      }
      object.node.style.left = calcXy(object.x);
      object.node.style.top = calcXy(object.y);
    })

    if (gameVisible) {
      hud.style.display = 'inherit';
      (hud.querySelector('.text') as HTMLElement).innerText = `${collectedEmojis}/${totalEmojis}`;
      (hud.querySelector('.bomb') as HTMLElement).innerText = `💣 ${numBombs}`;
      (hud.querySelector('.level') as HTMLElement).innerText = `✅${levelAt + 1}`;
    } else {
      hud.style.display = 'none';
    }
  }

  window.addEventListener('scroll', () => {
    const rect = emojisNode.getBoundingClientRect();
    gameVisible = rect.top < window.innerHeight && rect.bottom >= 0;
  });

  const raf = () => {
    window.requestAnimationFrame(() => {
      if (gameEnded) return;
      render();
      raf();
    });
  }
  raf();
  // setInterval(render, 1000 / 30);
}, { once: true });
